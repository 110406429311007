import React, { useEffect } from "react";
import { Box, Button, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { CompetitionList } from "redux/CompetitionList/Model";
import { competitionListByPatientIdApi } from "redux/PatientCompetitionListById/API";
import { CompetitionListByPatientIdBody } from "redux/PatientCompetitionListById/Model";

type Props = {
  encounterListLoader?: boolean | any;
};

export default function GroupEncounterListByPatient({
  encounterListLoader,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      competitionListByPatientIdApi(
        {
          patientId: "patientoriginalid",
        } as CompetitionListByPatientIdBody,
        value,
      ),
    );
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  useEffect(() => {
    dispatch(
      competitionListByPatientIdApi(
        {
          patientId: "patientoriginalid",
        } as CompetitionListByPatientIdBody,
        page,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const competitionList = useSelector((state: AppState) => state.competitionListByPatientIdValues);

  useEffect(() => {
    encounterListLoader(competitionList?.loading);
    competitionList?.competitionListByPatientIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(competitionList?.competitionListByPatientIdRes?.numberOfPages))
      : setPageListCount("0");
    if (competitionList?.competitionListByPatientIdRes?.competitionList !== undefined) {
      let dataPointArr = competitionList?.competitionListByPatientIdRes?.competitionList.map(
        (element: CompetitionList) => ({
          id: element.id,
          displayName: element.displayName,
          dateCreated: dayjs(element.dateCreated).format("DD/MM/YYYY"),
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionList]);

  const gotoSubscriptionDetails = (rowIndex: number) => {
    history.push(`/patientlistbycompetition/${rowIndex}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoSubscriptionDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Competition Name",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "dateCreated",
      headerName: "Created Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
  ];

  const groupEncounterListTable = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {competitionList?.competitionListByPatientIdRes?.competitionList &&
        competitionList?.competitionListByPatientIdRes?.competitionList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {groupEncounterListTable}
        </Stack>
      </Container>
    </Box>
  );
}
