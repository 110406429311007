import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { getAdminAppointmentList } from "../redux/Doctor/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { Box, Button, Card, CardContent, Container, Grid, Stack } from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import PreviewIcon from "@mui/icons-material/Preview";
import MWPageTitle from "../component/MWPageTitle";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { AdminAppointmentList } from "redux/Doctor/Model";
import dayjs from "dayjs";

export default function DoctorAppointments() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { doctorId } = useParams() as {
    doctorId: string;
  };
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [doctorAppoinmentListCount, setDoctorAppoinmentListCount] = React.useState("");
  function gotoDetails(patientId: number, caseId: Number) {
    history.push(`/casedetails/${caseId}/${patientId}`);
  }

  const appointmentBody = {
    targetDoctorId: doctorId,
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            gotoDetails(params.row.caseId, params.row.patientId);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      editable: false,
      width: 300,
      align: "left",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "create Date",
      editable: false,
      width: 150,
      align: "left",
    },
    {
      field: "endDate",
      headerName: "end Date",
      editable: false,
      width: 150,
      align: "left",
    },
    {
      field: "modifiedDate",
      headerName: "modified Date",
      editable: false,
      width: 150,
      align: "left",
    },
    {
      field: "appoinmentStatus",
      headerName: "Status",
      editable: false,
      width: 100,
      align: "left",
    },
  ];
  // Selection Type GET API
  const getAppoinmentRequestList = () => {
    setIsLoading(true);
    dispatch(getAdminAppointmentList(appointmentBody));
  };

  // CAll Appontment list from Store
  const getDoctorAppoinmentListValue = useSelector((state: AppState) => state.appointment);

  const appointmentList = useSelector((state: AppState) => state.appointment.appointmentValue);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    getDoctorAppoinmentListValue?.appointmentValue !== undefined
      ? setDoctorAppoinmentListCount(String(getDoctorAppoinmentListValue.appointmentValue.length))
      : setDoctorAppoinmentListCount("0");
    if (getDoctorAppoinmentListValue?.appointmentValue !== undefined) {
      let docAppoinmentArr = getDoctorAppoinmentListValue?.appointmentValue.map(
        (element: AdminAppointmentList) => ({
          id: element.id,
          patientId: element.patientId,
          caseId: element.caseId,
          description: element.name,
          createdDate:
            element.createDate === null
              ? "Not Set"
              : dayjs(element.createDate).format("ddd, MMM D, YYYY"),

          endDate: element.end === null ? "Not Set" : dayjs(element.end).format("ddd, MMM D, YYYY"),

          modifiedDate:
            element.modifiedDate === null
              ? "Not Set"
              : dayjs(element.modifiedDate).format("ddd, MMM D, YYYY"),
          appoinmentStatus: element.hasOwnProperty("status")
            ? element.status === null || element.status === ""
              ? ""
              : element.status
            : "",
        }),
      );
      setDataGridArray(docAppoinmentArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorAppoinmentListValue]);

  useEffect(() => {
    // Do api call here....
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadDocAppointmentList = () => {
    dispatch(getAppoinmentRequestList());
  };

  const DoctorAppoinmentList = (
    <Card>
      <CardContent>
        <Box>
          {appointmentList && appointmentList.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {getDoctorAppoinmentListValue.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title="Doctor Appointment List"
                enableCount={true}
                count={doctorAppoinmentListCount}
                backButton={true}
                reload={true}
                reloadAction={reloadDocAppointmentList}
              />
            </Grid>
          </Grid>
          {DoctorAppoinmentList}
        </Stack>
      </Container>
    </Box>
  );
}
