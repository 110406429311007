import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../redux/store/Store";
import { Alert, AlertColor, Box, Button, Grid } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import React from "react";
import MWSnackbar from "component/MWSnackbar";
import { GetSessionListBody, HealthcampSessionList } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import AddHealthcampSessionDialog from "./AddHealthcampSessionDialog";
import { clearAddSessionAPIRes } from "redux/AddSession/API";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { clearEditSessionAPIRes, updateEditSessionAPIResMsg } from "redux/EditSession/API";
import { clearEditInstanceAPIRes } from "redux/EditInstance/API";
import { clearAddInstanceAPIRes } from "redux/AddInstance/API";

type Props = {
  sessionListLoader?: boolean | any;
};

export default function SessionList({ sessionListLoader }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  // call Store
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const addResponse = useSelector((state: AppState) => state.addInstanceResValue);
  const editessionResponse = useSelector((state: AppState) => state.editSessionValue);
  const [sessionMsg, setSessionMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    dispatch(clearEditInstanceAPIRes());
    dispatch(clearAddInstanceAPIRes());
    const healthcampSessionListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetSessionListBody;
    dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [showSessionDialog, setShowSessionDialog] = React.useState(false);
  const [healthcampSessionId, setHealthcampSessionId] = React.useState("");
  const updateSessionDialogValFromChild = (passedVal: boolean) => {
    setShowSessionDialog(passedVal);
  };

  useEffect(() => {
    if (sessionListResponseValue?.getSessionList?.healthcampSessionList !== undefined) {
      let dataPointArr = sessionListResponseValue?.getSessionList?.healthcampSessionList.map(
        (element: HealthcampSessionList) => ({
          id: element.id,
          healthcampSessionId: element.id,
          sessionId: element.id,
          displayName: element.displayName,
          sessionDate:
            element.sessionDate !== null ? dayjs(element.sessionDate).format("MMM D, YYYY") : "",
          sessionStartTime: element.sessionStartTime !== null ? element.sessionStartTime : "",
          sessionEndTime: element.sessionEndTime !== null ? element.sessionEndTime : "",
        }),
      );
      setDataGridArray(dataPointArr);
    }
    if (sessionListResponseValue?.errorMsg !== "") {
      setNotAdministratorMsg(sessionListResponseValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionListResponseValue]);

  useEffect(() => {
    if (addResponse?.addInstanceRes?.message !== undefined) {
      setSessionMsg(addResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const healthcampSessionListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
      dispatch(clearAddSessionAPIRes());
    }
    if (addResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setSessionMsg(addResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  useEffect(() => {
    if (editessionResponse?.editSessionRes?.message !== undefined) {
      setSessionMsg(editessionResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      const healthcampSessionListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
      dispatch(clearEditSessionAPIRes());
    }
    if (editessionResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setSessionMsg(editessionResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editessionResponse]);

  function gotoDetails(sessionId: number) {
    history.push(
      `/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}`,
    );
    dispatch(clearEditSessionAPIRes());
    dispatch(clearAddSessionAPIRes());
    dispatch(clearEditInstanceAPIRes());
    dispatch(clearAddInstanceAPIRes());
    dispatch(updateEditSessionAPIResMsg());
  }
  const showDialog = () => {
    setShowSessionDialog(true);
    setHealthcampSessionId("");
  };
  const editSession = (instanceId: string) => {
    setHealthcampSessionId(instanceId);
    setShowSessionDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Session Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "sessionDate",
      headerName: "Session Date",
      editable: false,
      minWidth: 150,
      align: "left",
    },
    {
      field: "sessionStartTime",
      headerName: "Session Start Time",
      editable: false,
      minWidth: 150,
      align: "left",
    },
    {
      field: "sessionEndTime",
      headerName: "Session End Time",
      editable: false,
      minWidth: 150,
      align: "left",
    },
    {
      field: "healthcampSessionId",
      headerName: "Action",
      editable: false,
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => {
            editSession(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setSessionMsg("");
      dispatch(clearEditSessionAPIRes());
      dispatch(clearAddSessionAPIRes());
      dispatch(updateEditSessionAPIResMsg());
      dispatch(clearEditInstanceAPIRes());
      dispatch(clearAddInstanceAPIRes());
    }
  };

  return (
    <Box>
      {sessionListResponseValue?.loading === true
        ? sessionListLoader(true)
        : sessionListLoader(false)}
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        {notAdministratorMsg !== "" ? (
          <Grid item>
            <Alert severity="error">{notAdministratorMsg}</Alert>
          </Grid>
        ) : null}
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => showDialog()}
                  >
                    Add Session
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {sessionListResponseValue?.getSessionList?.healthcampSessionList &&
          sessionListResponseValue?.getSessionList?.healthcampSessionList.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
      {sessionMsg !== "" ? (
        <MWSnackbar
          msg={sessionMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
      {showSessionDialog ? (
        <AddHealthcampSessionDialog
          filterDialogEnable={showSessionDialog}
          filterDialogUpdateState={updateSessionDialogValFromChild}
          instanceHealthcampId={instanceHealthcampId}
          healthcampSessionId={healthcampSessionId}
        />
      ) : null}
    </Box>
  );
}
