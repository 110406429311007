import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Pagination,
  Typography,
  Button,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
  AlertColor,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import AddDataPointDialog from "./AddDataPointDialog";
import { DataGrid, GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { getPatientDataDetailsList } from "../../redux/patientDataDetails/patientDataPointListByPatientID/API";
import DocumentViewer from "./DocumentViewer";
import { getDemographicsDetailsByPtId } from "../../redux/DemographicsDetails/GetPatientDemographicsDetails/API";
import { PatientDemographicsReq } from "../../redux/Patient/DemographicsDetails/Model";
import { ExtractObservationDetailsBody } from "../../redux/extractObservationDetailsByPatientId/Model";
import {
  clearExtractObservationDetailsAPIRes,
  extractObservationByPatientId,
} from "../../redux/extractObservationDetailsByPatientId/API";
import MWSnackbar from "../../component/MWSnackbar";
import { NameGenerate } from "../../component/Utility";
import { getPatientDashBoardLatestDataList } from "../../redux/patientDashboardLatestDataList/ApiCall";
import { LatestDataListBody } from "../../redux/patientDashboardLatestDataList/Model";
import {
  addPatientDataDetailsUpdateAPIResMsg,
  clearPatientDataPointClearAPIRes,
} from "redux/patientDataDetails/addPatientDetails/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

type Props = {
  toggleState: boolean | any;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientDataDetails({ toggleState }: Props) {
  let query = useQuery();
  const patientDetailsId = query.get("documentid");
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const history = useHistory();
  const patientFullName = useSelector(
    (state: AppState) =>
      state.getDemographicsDetailsByPtId?.getDemographicsDetailsByPtIdRes?.personalDetails,
  );

  useEffect(() => {
    if (patientDetailsId !== null) {
      setDocViwerActive(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsId]);

  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
    const doesAnyHistoryEntryExist = history.location.key;
    if (doesAnyHistoryEntryExist) {
      history.goBack();
    }
  };
  const [docViwerActive, setDocViwerActive] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPatientDataDetailsList(patientid, page));
    }
  };
  // patient details list api call ...
  useEffect(() => {
    if (patientid) {
      dispatch(getPatientDataDetailsList(patientid, page));
      dispatch(
        getDemographicsDetailsByPtId({
          healthRecordId: patientid,
        } as PatientDemographicsReq),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [extractSuccessMessage, setExtractSuccessMessage] = useState("");
  const [addDetailsSuccessMessage, setAddDetailsSuccessMessage] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const extractObservationByPatientIdVal = useSelector(
    (state: AppState) => state.extractObservationByPatientIdValue,
  );
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearExtractObservationDetailsAPIRes());
      dispatch(clearPatientDataPointClearAPIRes());
    }
  };
  useEffect(() => {
    if (extractObservationByPatientIdVal?.extractObservationDetailsRes?.message !== undefined) {
      setMsgType("success");
      setExtractSuccessMessage(
        extractObservationByPatientIdVal?.extractObservationDetailsRes?.message,
      );
      dispatch(
        getPatientDashBoardLatestDataList({
          patientId: patientid,
        } as LatestDataListBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractObservationByPatientIdVal]);

  useEffect(() => {
    setPatientName(patientFullName?.firstname + " " + patientFullName?.lastname);
  }, [patientFullName]);

  useEffect(() => {
    if (patientFullName?.firstname !== undefined && patientFullName?.lastname !== undefined) {
      setPageTitle(
        "Patient Details List of " +
          NameGenerate.changeName(
            patientid,
            patientFullName?.firstname + " " + patientFullName?.lastname,
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState, patientFullName]);

  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDataDetailsList(patientid, value));
  };

  React.useEffect(() => {
    if (addDataDetailsStoreVal?.successMsg !== "") {
      setAddDetailsSuccessMessage(addDataDetailsStoreVal?.successMsg);
      setMsgType("success");
      dispatch(addPatientDataDetailsUpdateAPIResMsg());
    }
    if (addDataDetailsStoreVal?.errorMsg !== "") {
      setAddDetailsSuccessMessage(addDataDetailsStoreVal?.errorMsg);
      setMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);

  const [dataGridArray, setDataArray] = React.useState([] as any[]);

  function formatSchedule(schedule: any) {
    return schedule.replace(/(\d{1,2})-(\d{2}) ([AP]M) ~ (\d{1,2})-(\d{2}) ([AP]M)/, '$1:$2 $3 to $4:$5 $6');
  }
  
  dataGridArray.forEach(entry => {
    entry.description = entry.description.replace(/sleepschedule: ([^;]+)/, (p1:any) => {
      return `${formatSchedule(p1)}`;
    });
  });
  useEffect(() => {
    patientPointDataList?.pageCount !== undefined
      ? setPageListCount(String(patientPointDataList?.pageCount))
      : setPageListCount("0");

    patientPointDataList?.getPatientDataDetailsRes?.count !== undefined
      ? setListCount(String(patientPointDataList?.getPatientDataDetailsRes?.count))
      : setListCount("0");

    if (patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList !== undefined) {
      let dataPointArr = patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList.map(
        (element: any) => ({
          id: element.id,
          patientId: element.patientId,
          description: element.description,
          detailsType: element.sectionType,
          startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
          date: element.startDate,
          image:
            element?.patientDocument?.document !== undefined
              ? element?.patientDocument?.document
              : "",
          imageId:
            element?.patientDocument?.id !== undefined
              ? String(element?.patientDocument?.id)
              : undefined,
        }),
      );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(String(patientPointDataList?.pageCount));
    }
  }, [patientPointDataList]);

  const [selectedItem, setSelectedItem] = React.useState<any>(({} as any) || undefined);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      align: "left",
      minWidth: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => {
            setDocViwerActive(true);
            setSelectedItem(Object.assign(params.row, { patientName }));
            history.push(`/patientdetails/${params.row.patientId}?documentid=${params.row.id}`);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient details I'd",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "detailsType",
      headerName: "Patient Details Type",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 240,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      minWidth: 240,
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const patientExtractObservation = () => {
    let extractPBody = {
      patientId: patientid,
    } as ExtractObservationDetailsBody;
    dispatch(extractObservationByPatientId(extractPBody));
  };

  const [showAddDataPointDialog, setShowAddDataPointDialog] = React.useState(false);
  const updateDialogState = () => {
    setShowAddDataPointDialog(true);
  };
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
    setSelectedItem(undefined);
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(patientPointDataList?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={pageTitle}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
          <Grid item m={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item>
                <Button variant="outlined" onClick={patientExtractObservation}>
                  Extract Observation
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => updateDialogState()}>
                  Add Patient Details
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {patientPointDataTable}
        {extractSuccessMessage !== "" && (
          <MWSnackbar msg={extractSuccessMessage} type={msgType} alertClose={checkAlertClose} />
        )}

        {showAddDataPointDialog ? (
          <AddDataPointDialog
            addDataPointDialogEnable={showAddDataPointDialog}
            addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
            page={page}
          />
        ) : null}
        {addDetailsSuccessMessage !== "" && (
          <MWSnackbar msg={addDetailsSuccessMessage} type={msgType} alertClose={checkAlertClose} />
        )}
        {docViwerActive ? (
          <DocumentViewer
            dialogState={docViwerActive}
            dialogClose={dialogClose}
            value={selectedItem}
            page={page}
          />
        ) : null}
      </Stack>
    </Container>
  );
}
