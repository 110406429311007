import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MWExceptionList from "../../../component/MWExceptionList";
import { clearOrgPtSearchAPIRes } from "../../../redux/PatientSearchByOrganizationId/API";
// import AddPatientDialog from "pages/PatientListByOrganization/AddPatientDialog";
import { getPatientListOfCompetition } from "redux/PatientListOfCompetition/API";
import { GetPatientListOfCompetitionBody, PatientDetailsList } from "redux/PatientListOfCompetition/Model";

type Props = {
  patientListOfCompetitionLoader?: boolean | any;
};

export default function PatientListOfCompitition({patientListOfCompetitionLoader} : Props) {
  const dispatch = useDispatch();
  const { competitionid } = useParams() as {
    competitionid: string;
  };

  const history = useHistory();

  const [page, setPage] = useState(1);
  function gotoDetails(patientId: number) {
    history.push(`/patientdetailslist/${patientId}/${competitionid}`);
  }
  const [pageListCount, setPageListCount] = React.useState("");


  useEffect(() => {
    const patientListBody = {
      competitionId: competitionid,
    } as GetPatientListOfCompetitionBody;
    dispatch(getPatientListOfCompetition(patientListBody, page));
    dispatch(clearOrgPtSearchAPIRes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.getPatientListOfCompetitionValue);
  const allcaseValues = AdminPatientList?.PatientListOfCompetitionDetailsRes?.patientDetailsList;

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (allcaseValues !== undefined) {
      const patientListBody = {
        competitionId: competitionid,
      } as GetPatientListOfCompetitionBody;
      dispatch(getPatientListOfCompetition(patientListBody, value));
      dispatch(clearOrgPtSearchAPIRes());
    }
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    AdminPatientList?.PatientListOfCompetitionDetailsRes?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.PatientListOfCompetitionDetailsRes?.numberOfPages))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientDetailsList) => ({
        id: element.patientId,
        firstname: element.description,
        patientId: element.patientId,
        createdDate: dayjs(element.startDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 120,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 120,
      align: "left",
    },
    {
      field: "firstname",
      headerName: "Name",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      editable: false,
      minWidth: 200,
      align: "left",
    },

  ];

  const PatientListOfCompetition = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
                pt={2}
              >
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {allcaseValues && allcaseValues.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminPatientList.loading === true
        ? patientListOfCompetitionLoader(true)
        : patientListOfCompetitionLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {PatientListOfCompetition}
        </Stack>
      </Container>
    </Box>
  );
}
