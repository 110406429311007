import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import { useParams } from "react-router-dom";
import AddHealthcampStaffDialog from "../AddHealthcampStaffDialog";
import { DeactiveStaffOfHealthCampBody } from "redux/HealthcampStaffList/DeactiveStaffOfHealthcamp/Model";
import {
  clearDiactiveStaffOfHealthcampAPIRes,
  deactiveStaffOfHealthcampApiCall,
} from "redux/HealthcampStaffList/DeactiveStaffOfHealthcamp/API";
import MWSnackbar from "component/MWSnackbar";
import {
  GetHealthcampStaffInactiveListBody,
  StaffList,
} from "redux/HealthcampStaffList/InactiveStaffList/Model";
import { getInactiveListByHealthCampId } from "redux/HealthcampStaffList/InactiveStaffList/API";

type Props = {
  viewHealthcampStaffListLoader?: boolean | any;
  inactiveListCount: (value: string) => void;
};

export default function DeactiveList({ viewHealthcampStaffListLoader, inactiveListCount }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  // call Store
  const viewHealthCampStaffInactiveList = useSelector(
    (state: AppState) => state.inactiveStaffListValue,
  );
  const deactivateHealthcampStaffValueList = useSelector(
    (state: AppState) => state.deactivateHealthcampStaffValue,
  );
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const viewHealthcampStaffListBody = {
    healthcampId: healthcampid,
    isActiveOrAdmin: 0,
  } as GetHealthcampStaffInactiveListBody;

  useEffect(() => {
    dispatch(getInactiveListByHealthCampId(viewHealthcampStaffListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const deactiveStaff = (id: string) => {
    let diactiveBody = {
      healthcampId: healthcampid,
      staffId: id,
    } as DeactiveStaffOfHealthCampBody;
    dispatch(deactiveStaffOfHealthcampApiCall(diactiveBody));
  };

  useEffect(() => {
    if (deactivateHealthcampStaffValueList?.deactiveStaffOfHealthcampRes?.message !== undefined) {
      setDiactiveMsg(deactivateHealthcampStaffValueList?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getInactiveListByHealthCampId(viewHealthcampStaffListBody, 1));
      dispatch(clearDiactiveStaffOfHealthcampAPIRes());
    }
    if (deactivateHealthcampStaffValueList?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(deactivateHealthcampStaffValueList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateHealthcampStaffValueList]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [addHealthcampStaffDialog, setAddHealthcampStaffDialog] = React.useState(false);
  const updateAddOrganizationStaffDialogValFromChild = (passedVal: boolean) => {
    setAddHealthcampStaffDialog(passedVal);
  };
  useEffect(() => {
    viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.numberOfPages !== undefined
      ? setPageListCount(
          String(viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.numberOfPages),
        )
      : setPageListCount("0");
    viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.count !== undefined
      ? inactiveListCount(
          String(viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.count),
        )
      : inactiveListCount("0");

    if (viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.StaffList !== undefined) {
      if (viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.StaffList.length > 0) {
        let dataPointArr =
          viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.StaffList.map(
            (element: StaffList) => ({
              id: element.id,
              first_name: element.first_name,
              last_name: element.last_name,
              email: element.email,
              phoneNumber: element.phoneNumber,
              userId: element.userId,
            }),
          );
        setDataGridArray(dataPointArr);
      } else {
        setDataGridArray([] as any[]);
      }
    }
    if (viewHealthCampStaffInactiveList?.errorMsg !== "") {
      setNotAdministratorMsg(viewHealthCampStaffInactiveList?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewHealthCampStaffInactiveList]);

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      minWidth: 220,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      minWidth: 124,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deactiveStaff(params.value);
          }}
          startIcon={<ToggleOnIcon />}
          sx={{ textTransform: "none" }}
        >
          Active Staff
        </Button>
      ),
    },
  ];

  const StaffListOfHealthCamp = (
    <Box>
      {viewHealthCampStaffInactiveList?.getHealthcampStaffInactiveList?.StaffList !== undefined ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item></Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item></Grid>
              <Grid item>
                <Typography>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount) || 0}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid mt={2}>
        {viewHealthCampStaffInactiveList?.status === -1 ? (
          <Alert severity="error">{notAdministratorMsg}</Alert>
        ) : (
          <Box>
            {dataGridArray.length > 0 ? (
              <Box>
                {fullScreen ? (
                  <Box sx={{ height: "calc(100vh - 54vh)" }}>
                    <DataGrid
                      rows={dataGridArray}
                      columns={columns}
                      disableSelectionOnClick
                      hideFooter
                      hideFooterPagination
                      experimentalFeatures={{ newEditingApi: true }}
                      components={{ Toolbar: GridToolbar }}
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 },
                        },
                      }}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                    />
                  </Box>
                ) : (
                  <DataGrid
                    rows={dataGridArray}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    hideFooter
                    hideFooterPagination
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                )}
              </Box>
            ) : (
              <MWExceptionList />
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
    }
  };

  return (
    <Box>
      {viewHealthCampStaffInactiveList?.loading === true
        ? viewHealthcampStaffListLoader(true)
        : viewHealthcampStaffListLoader(false)}
      {StaffListOfHealthCamp}
      {addHealthcampStaffDialog ? (
        <AddHealthcampStaffDialog
          itemId={healthcampid}
          addOrganizationStaffDialogEnable={addHealthcampStaffDialog}
          addOrganizationStaffDialogUpdateState={updateAddOrganizationStaffDialogValFromChild}
        />
      ) : null}
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
