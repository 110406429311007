import { AlertColor, Box, Button, Grid, Pagination, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import dayjs from "dayjs";
import {
  GetPatientListBySessionIdBody,
  PatientList,
} from "redux/HealthcampSessionList/PatientListBySessionId/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWExceptionList from "component/MWExceptionList";
import { getPatientListBySessionIdApi } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import MWSnackbar from "component/MWSnackbar";
import {
  clearAddPatientBySessionIdAPIRes,
  updateAddPatientBySessionIdAPIResMsg,
} from "redux/HealthcampSessionList/AddPatientBySessionId/API";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterHealthCampDialog from "./FilterHealthCampDialog";
import { ExtractObsTypeBySessionIdBody } from "redux/extractPatientDetailsBySessionId/Model";
import {
  clearExtractObsTypeOfSessionIdAPIRes,
  extractObsTypeBySessionId,
} from "redux/extractPatientDetailsBySessionId/API";
import { clearEditSessionAPIRes } from "redux/EditSession/API";
import { clearAddSessionAPIRes } from "redux/AddSession/API";
import { clearEditInstanceAPIRes } from "redux/EditInstance/API";
import { clearAddInstanceAPIRes } from "redux/AddInstance/API";
import { SessionPatientAvailableByObservationTypeBody } from "redux/HealthcampSessionList/AvailablePatientObservationType/Model";
import { sessionPatientAvailableByObservationTypeApi } from "redux/HealthcampSessionList/AvailablePatientObservationType/API";

type Props = {
  patientListCount: (value: string) => void;
  patientListLoader: boolean | any;
};

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientListBySessionId({ patientListCount, patientListLoader }: Props) {
  let query = UseQuery();
  const obType = query.get("obType");
  const date = query.get("date");
  const status = query.get("status");
  const dispatch = useDispatch();
  const history = useHistory();
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const [diactiveMsg, setDiactiveMsg] = React.useState("");
  const [extractMsg, setExtractMsg] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    useState<AlertColor>("success");
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [availablePageListCount, setAvailablePageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (obType !== null && date !== null && status !== null) {
      const PatientSearchByObservationBody = {
        patientObservationType: decodeURIComponent(obType),
        healthcampSessionId: sessionId,
        startDate: dayjs(decodeURIComponent(date)).format("YYYY-MM-DD"),
        isMissingData: status,
      } as SessionPatientAvailableByObservationTypeBody;
      dispatch(sessionPatientAvailableByObservationTypeApi(PatientSearchByObservationBody, value));
    } else {
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientListBySessionIdBody;
      dispatch(getPatientListBySessionIdApi(apiBody, value));
    }
  };

  useEffect(() => {
    dispatch(clearEditSessionAPIRes());
    dispatch(clearAddSessionAPIRes());
    dispatch(clearAddPatientBySessionIdAPIRes());
    let apiBody = {
      healthcampSessionId: sessionId,
    } as GetPatientListBySessionIdBody;
    dispatch(getPatientListBySessionIdApi(apiBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getPatientListValues = useSelector(
    (state: AppState) => state.getPatientListBySessionIdValues,
  );
  const allcaseValues = getPatientListValues?.getPatientListBySessionIdRes?.patientList;
  const vitalCollectionActiveStatusList = useSelector(
    (state: AppState) => state.getVitalCollectionActiveList,
  );
  const addPatientResponse = useSelector((state: AppState) => state.addPatientBySessionIdValues);
  const extractObsValue = useSelector((state: AppState) => state.extractObsBySessionIdResult);
  const availableDataByObservationType = useSelector(
    (state: AppState) => state.avaliableObservationResponse,
  );
  const patientDataRangeValues = useSelector(
    (state: AppState) => state.patientDataRangBySessionIdResult,
  );
  const mandatoryPatientDetailsValue = useSelector(
    (state: AppState) => state.getMandatoryPatientDetailsValue,
  );
  console.log("mandatoryPatientDetailsValue===", mandatoryPatientDetailsValue);
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [showFilterDialog, setShowFilterDialog] = React.useState(false);
  const updateFilterDialogValFromChild = (dialogPassedVal: boolean) => {
    setShowFilterDialog(dialogPassedVal);
  };

  useEffect(() => {
    if (addPatientResponse?.addPatientBySessionIdRes?.message !== undefined) {
      setDiactiveMsg(addPatientResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientListBySessionIdBody;
      dispatch(getPatientListBySessionIdApi(apiBody, 1));
      dispatch(updateAddPatientBySessionIdAPIResMsg());
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
    if (addPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setDiactiveMsg(addPatientResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientResponse]);

  useEffect(() => {
    if (extractObsValue?.extractObsTypeBySessionId?.message !== undefined) {
      setExtractMsg(extractObsValue?.successMsg);
      setLinkImageToCompetitionMsgType("success");
    }
    if (extractObsValue?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setExtractMsg(extractObsValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractObsValue]);

  useEffect(() => {
    patientListLoader(getPatientListValues?.loading);
    getPatientListValues?.getPatientListBySessionIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(getPatientListValues?.getPatientListBySessionIdRes?.numberOfPages))
      : setPageListCount("0");
    getPatientListValues?.getPatientListBySessionIdRes?.count !== undefined &&
      patientListCount(String(getPatientListValues?.getPatientListBySessionIdRes?.count));
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        username: element.userName,
        phone: element.phone,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListValues]);

  useEffect(() => {
    availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.numberOfPages !==
    undefined
      ? setAvailablePageListCount(
          String(
            availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes
              ?.numberOfPages,
          ),
        )
      : setAvailablePageListCount("0");
    if (
      availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.count !==
      undefined
    ) {
      patientListCount(
        String(availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.count),
      );
    }
    if (
      availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.patientList !==
      undefined
    ) {
      let dataPointArr =
        availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.patientList.map(
          (element: any) => ({
            id: element.patientId,
            firstname: element.givenName,
            lastname: element.familyName,
            username: element.userName,
            phone: element.phone,
            patientId: element.patientId,
            dob: dayjs(element.dob).format("MMMM D, YYYY"),
            createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
          }),
        );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDataByObservationType]);

  useEffect(() => {
    vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.count !== undefined) {
      patientListCount(String(vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.count));
    }
    if (vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.patientList !== undefined) {
      let dataPointArr =
        vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.patientList.map(
          (element: PatientList) => ({
            id: element.patientId,
            firstname: element.givenName,
            lastname: element.familyName,
            username: element.userName,
            phone: element.phone,
            patientId: element.patientId,
            dob: dayjs(element.dob).format("MMMM D, YYYY"),
            createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
          }),
        );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalCollectionActiveStatusList]);

  useEffect(() => {
    patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.count !== undefined) {
      patientListCount(String(patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.count));
    }
    if (patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.patientList !== undefined) {
      let dataRangeValue =
        patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.patientList.map(
          (element: any) => ({
            id: element.patientId,
            firstname: element.givenName,
            lastname: element.familyName,
            username: element.userName,
            phone: element.phone,
            patientId: element.patientId,
            dob: dayjs(element.dob).format("MMMM D, YYYY"),
            createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
          }),
        );
      setDataGridArray(dataRangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataRangeValues]);

  useEffect(() => {
    mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.numberOfPages !==
    undefined
      ? setPageListCount(
          String(
            mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes
              ?.numberOfPages,
          ),
        )
      : setPageListCount("0");
    if (
      mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.count !==
      undefined
    ) {
      patientListCount(
        String(mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.count),
      );
    }
    if (
      mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.patientList !==
      undefined
    ) {
      let dataRangeValue =
        mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.patientList.map(
          (element: any) => ({
            id: element.patientId,
            firstname: element.givenName,
            lastname: element.familyName,
            username: element.userName,
            phone: element.phone,
            patientId: element.patientId,
            dob: dayjs(element.dob).format("MMMM D, YYYY"),
            createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
          }),
        );
      setDataGridArray(dataRangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryPatientDetailsValue]);

  function gotoDetails(patientId: number, pageNo: number) {
    dispatch(clearEditSessionAPIRes());
    dispatch(clearAddSessionAPIRes());
    dispatch(clearEditInstanceAPIRes());
    dispatch(clearAddInstanceAPIRes());
    history.push(
      `/healthcampsession/${healthcampid}/${instanceHealthcampId}/${sessionId}/${patientId}-${pageNo}`,
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value, page);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone No",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "username",
      headerName: "User Name",
      editable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: "Create date",
      type: "string",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified date",
      type: "string",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    obType !== null && date !== null ? setOpen(true) : setOpen(false);
  }, [obType, date]);

  const goToCheckInPatient = () => {
    history.push(`/healthcamppatientcheckin/${healthcampid}/${instanceHealthcampId}/${sessionId}`);
  };

  const extractPatientDetails = () => {
    dispatch(
      extractObsTypeBySessionId(
        { healthcampSessionId: sessionId } as ExtractObsTypeBySessionIdBody,
        1,
      ),
    );
  };

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      setDiactiveMsg("");
      dispatch(clearAddPatientBySessionIdAPIRes());
    }
  };

  const extractClose = (passedVal: boolean) => {
    if (passedVal) {
      setExtractMsg("");
      dispatch(clearExtractObsTypeOfSessionIdAPIRes());
    }
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Tooltip
                open={open}
                title={
                  obType !== null && date !== null
                    ? `Observation Type= ${obType}, Date= ${dayjs(date).format("DD/MM/YYYY")}`
                    : ""
                }
                arrow
              >
                <Button
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                  onClick={() => {
                    setShowFilterDialog(true);
                  }}
                >
                  Filter
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={() => extractPatientDetails()}
                  >
                    Extract Patient Details
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => goToCheckInPatient()}
                  >
                    Check-In
                  </Button>
                </Grid>
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || Number(availablePageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {(allcaseValues && allcaseValues.length) ||
          (availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes
            ?.patientList &&
            availableDataByObservationType?.sessionPatientAvailableByObservationTypeRes?.patientList
              .length) ||
          (vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.patientList &&
            vitalCollectionActiveStatusList?.getVitalCollectionActiveRes?.patientList.length) ||
          (patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.patientList &&
            patientDataRangeValues?.patientDataRangeBySessionIdTypeRes?.patientList.length) ||
          (mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes
            ?.patientList &&
            mandatoryPatientDetailsValue?.getMandatoryPendingDetailsListBySessionIdRes?.patientList
              .length) ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
      {diactiveMsg !== "" ? (
        <MWSnackbar
          msg={diactiveMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={addImgtoComp}
        />
      ) : null}
      {extractMsg !== "" ? (
        <MWSnackbar
          msg={extractMsg}
          type={linkImageToCompetitionMsgType}
          alertClose={extractClose}
        />
      ) : null}
      {showFilterDialog ? (
        <FilterHealthCampDialog
          filterDialogEnable={showFilterDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          sessionId={sessionId}
          obType={obType}
          date={date}
        />
      ) : null}
    </Box>
  );
}
