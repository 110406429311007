import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../../../../../redux/store/Store";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Pagination,
  Typography,
  Box,
  Button,
  Grid,
  AlertColor,
  Chip,
  ButtonGroup,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../../../../../../component/MWExceptionList";
import { clearAddPatientAPIRes } from "redux/addHealthcampPatient/API";
import MWSnackbar from "component/MWSnackbar";
import DocumentViewer from "pages/PatientDataDetails/DocumentViewer";
import { getPatientDetailsListBySessionIdApi } from "redux/HealthcampSessionList/PatientDetailsListBySessionId/API";
import {
  GetPatientDetailsListBySessionIdBody,
  PatientDetailsList,
} from "redux/HealthcampSessionList/PatientDetailsListBySessionId/Model";
import { clearPatientDataPointClearAPIRes } from "redux/HealthcampSessionList/addPatientDetails/API";
import { clearAddExistingPatientDetailsBySessionIdAPIRes } from "redux/AddExistingPatientDetailsBySession/API";
import { HealthCampList } from "redux/healthcampList/Model";
import { GetInstanceListBody, HealthcampInstanceList } from "redux/InstanceListOfHealthcamp /Model";
import { GetSessionListBody } from "redux/HealthcampSessionList/Model";
import { getSessionListByHealthCampId } from "redux/HealthcampSessionList/API";
import { getInstanceListByHealthCampId } from "redux/InstanceListOfHealthcamp /API";
import { getHealthcampListValue } from "redux/healthcampList/API";
import _ from "lodash";
import {
  GetPatientListBySessionIdBody,
  PatientList,
} from "redux/HealthcampSessionList/PatientListBySessionId/Model";
import { getPatientListBySessionIdApi } from "redux/HealthcampSessionList/PatientListBySessionId/API";
import RefreshIcon from "@mui/icons-material/Refresh";
import { VitalCollectionActiveInactiveStatusBody } from "redux/VitalCollectionStatus/ActiveInactiveDataStatus/Model";
import { vitalCollectionActiveInactiveStatusApi } from "redux/VitalCollectionStatus/ActiveInactiveDataStatus/API";
import { getVitalCollectionStatusApi } from "redux/VitalCollectionStatus/GetVitalCollectionStatus/API";
import { GetVitalCollectionStatusBody } from "redux/VitalCollectionStatus/GetVitalCollectionStatus/Model";
import AddPatientDetailsDialog from "../AddPatientDetailsDialog";
import AddExistingPatientDetailsBySessionIdDialog from "../AddExistingPatientDetailsBySessionIdDialog";
import { getPendingPatientDetailsTypeListBySessionIdApi } from "redux/HealthcampSessionList/PendingMandatoryPatientDetailsType/API";
import {
  GetPendingPatientDetailsTypeListBySessionIdBody,
  MandatoryPatientDeatilTypeList,
} from "redux/HealthcampSessionList/PendingMandatoryPatientDetailsType/Model";

type Props = {
  patientDetailsListLoader: boolean | any;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function PatientDetailsListBySessionId({ patientDetailsListLoader }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const healthcampPatientDetailsId = query.get("documentid");

  const { patientid } = useParams() as {
    patientid: string;
  };
  const patientId = patientid.split("-")[0];
  const pageNo = patientid.split("-")[1];

  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const sessionListResponseValue = useSelector((state: AppState) => state.sessionListValues);
  const instanceListResponseValue = useSelector((state: AppState) => state.instanceListValue);
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);

  const addPatientDetailsInCompetitionRes = useSelector(
    (state: AppState) => state.addPatientDetailsInCompetitionValue,
  );
  const patientPointDataList = useSelector(
    (state: AppState) => state.patientDetailsBySessionIdValues,
  );
  const addDataDetailsStoreVal = useSelector(
    (state: AppState) => state.addPatientDetailsBySessionValue,
  );
  const addExistingPatientResponse = useSelector(
    (state: AppState) => state.addExistingPatientDetailsValue,
  );
  const getPatientListValues = useSelector(
    (state: AppState) => state.getPatientListBySessionIdValues,
  );
  const activeInactiveResponse = useSelector((state: AppState) => state.activeInactiveStatusValue);
  const vitalCollectionStatus = useSelector(
    (state: AppState) => state.getVitalCollectionStatusResponse,
  );
  const pendingPatientDetailsTypeListRes = useSelector(
    (state: AppState) => state.getPendingPatientDetailsTypeListValue,
  );

  const [page, setPage] = React.useState(1);
  const patientDetailsBody = {
    healthcampSessionId: String(sessionId),
    patientId: String(patientId),
  } as GetPatientDetailsListBySessionIdBody;
  const [pendingDisplayValue, setPendingDisplayValue] = React.useState<any>(null);
  const [pageListCount, setPageListCount] = React.useState("");
  const [getPatientListObject, setGetPatientListObject] = React.useState<PatientList | undefined>(
    undefined,
  );
  const [updateDataPointSuccess, setUpdateDataPointSuccess] = React.useState("");
  const [statusSuccess, setStatusSuccess] = React.useState("");
  const [linkImageToCompetitionMsgType, setLinkImageToCompetitionMsgType] =
    React.useState<AlertColor>("success");
  const sessionValues: any | undefined = _.find(
    sessionListResponseValue?.getSessionList?.healthcampSessionList,
    ["id", Number(sessionId)],
  );
  const healthcampValues: HealthCampList | undefined = _.find(
    AdminAllHealthcampList?.healthcampListValue?.HealthCampList,
    ["id", Number(healthcampid)],
  );
  const instanceValues: HealthcampInstanceList | undefined = _.find(
    instanceListResponseValue.getInstanceList?.healthcampInstanceList,
    ["id", Number(instanceHealthcampId)],
  );
  const [showAddDataPointDialog, setShowAddDataPointDialog] = React.useState(false);
  const [showAddExistingPatientDetailsDialog, setShowAddExistingPatientDetailsDialog] =
    React.useState(false);
  const updateFilterDialogValFromChild = (passedVal: boolean) => {
    setShowAddExistingPatientDetailsDialog(passedVal);
  };
  const updateDialogState = () => {
    setShowAddDataPointDialog(true);
  };
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
  };
  const updatePendingDisplayValueFromChild = (updatedVal: object) => {
    setPendingDisplayValue(updatedVal);
  };
  // for popup reload
  useEffect(() => {
    if (healthcampPatientDetailsId !== null) {
      setDocViwerActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampPatientDetailsId]);

  useEffect(() => {
    if (getPatientListObject === undefined) {
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetPatientListBySessionIdBody;
      dispatch(getPatientListBySessionIdApi(apiBody, pageNo));
    }
    dispatch(
      getPendingPatientDetailsTypeListBySessionIdApi({
        healthcampSessionId: sessionId,
        patientId: patientId,
      } as GetPendingPatientDetailsTypeListBySessionIdBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListObject]);

  useEffect(() => {
    if (getPatientListValues?.getPatientListBySessionIdRes?.patientList !== undefined) {
      setGetPatientListObject(
        _.find(getPatientListValues?.getPatientListBySessionIdRes?.patientList, [
          "patientId",
          Number(patientId),
        ]),
      );
    } else {
      setGetPatientListObject(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientListValues]);

  useEffect(() => {
    if (sessionValues === undefined) {
      const healthcampSessionListBody = {
        healthcampInstanceId: instanceHealthcampId,
      } as GetSessionListBody;
      dispatch(getSessionListByHealthCampId(healthcampSessionListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionValues]);

  useEffect(() => {
    if (instanceValues === undefined) {
      const viewHealthcampStaffListBody = {
        healthcampId: healthcampid,
      } as GetInstanceListBody;
      dispatch(getInstanceListByHealthCampId(viewHealthcampStaffListBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceValues]);

  useEffect(() => {
    if (healthcampValues === undefined) {
      dispatch(getHealthcampListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampValues]);

  useEffect(() => {
    dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);
  useEffect(() => {
    if (activeInactiveResponse?.successMsg !== "") {
      setStatusSuccess(activeInactiveResponse?.successMsg);
      let getStatusBody = {
        healthcampSessionId: String(sessionId),
        patientId: String(patientId),
      } as GetVitalCollectionStatusBody;
      dispatch(getVitalCollectionStatusApi(getStatusBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInactiveResponse]);

  const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
  // Initiate Patient Details List API call when user edit patient details
  useEffect(() => {
    if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInactiveResponse]);
  React.useEffect(() => {
    if (addDataDetailsStoreVal?.addPatientDataDetailsRes?.message !== undefined) {
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
      dispatch(clearPatientDataPointClearAPIRes());
      let getStatusBody = {
        healthcampSessionId: String(sessionId),
        patientId: String(patientId),
      } as GetVitalCollectionStatusBody;
      dispatch(getVitalCollectionStatusApi(getStatusBody));
    }
    if (addDataDetailsStoreVal?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);

  React.useEffect(() => {
    if (
      addExistingPatientResponse?.addExistingPatientDetailsBySessionIdRes?.message !== undefined
    ) {
      setUpdateDataPointSuccess(addExistingPatientResponse?.successMsg);
      setLinkImageToCompetitionMsgType("success");
      dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
      dispatch(clearAddExistingPatientDetailsBySessionIdAPIRes());
    }
    if (addExistingPatientResponse?.errorMsg !== "") {
      setLinkImageToCompetitionMsgType("error");
      setUpdateDataPointSuccess(addExistingPatientResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientResponse]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, value));
  };
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    patientDetailsListLoader(patientPointDataList?.loading);
    patientPointDataList?.getPatientDetailsListBySessionIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(patientPointDataList?.getPatientDetailsListBySessionIdRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (
      patientPointDataList?.getPatientDetailsListBySessionIdRes?.patientDetailsList !== undefined
    ) {
      let getStatusBody = {
        healthcampSessionId: String(sessionId),
        patientId: String(patientId),
      } as GetVitalCollectionStatusBody;
      dispatch(getVitalCollectionStatusApi(getStatusBody));
      let dataPointArr =
        patientPointDataList?.getPatientDetailsListBySessionIdRes?.patientDetailsList.map(
          (element: PatientDetailsList) => ({
            id: element.id,
            description: element.description,
            detailsType: element.sectionType,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(patientPointDataList?.getPatientDetailsListBySessionIdRes?.numberOfPages),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            setSelectedItem(Object.assign(params.row, params.id));
            history.push(
              `/healthcampsession/${healthcampid}/${instanceHealthcampId}/${sessionId}/${patientId}-${pageNo}/?documentid=${params.row.id}`,
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Details Id",
      type: "string",
      minWidth: 180,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "detailsType",
      headerName: "Patient Details Type",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 230,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      minWidth: 250,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
  ];

  const patientDetailsListApi = () => {
    dispatch(getPatientDetailsListBySessionIdApi(patientDetailsBody, page));
  };

  const statusActiveInactive = () => {
    let activeInactiveBody = {
      healthcampSessionId: String(sessionId),
      patientId: String(patientId),
    } as VitalCollectionActiveInactiveStatusBody;
    dispatch(vitalCollectionActiveInactiveStatusApi(activeInactiveBody));
  };
  const [docViwerActive, setDocViwerActive] = React.useState(false);
  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
    const doesAnyHistoryEntryExist = history.location.key;
    if (doesAnyHistoryEntryExist) {
      history.goBack();
    }
  };
  const [selectedItem, setSelectedItem] = React.useState<any>(({} as any) || undefined);
  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(clearAddPatientAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);
  const updateAddPatientDetailsInCompetitionRes = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddPatientAPIRes());
    }
  };
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearPatientDataPointClearAPIRes());
      setStatusSuccess("");
    }
  };

  const openAddDetailsDialog = (displayValue: object | null) => {
    setPendingDisplayValue(displayValue);
    setShowAddDataPointDialog(true);
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <h5>{`Patient Details List of ${
                    getPatientListObject?.givenName + " " + getPatientListObject?.familyName
                  }`}</h5>
                </Grid>
                <Grid item>
                  <Chip
                    label={String(patientPointDataList?.getPatientDetailsListBySessionIdRes?.count)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <RefreshIcon
                    sx={{ mt: 2, cursor: "pointer" }}
                    onClick={() => {
                      patientDetailsListApi();
                      dispatch(
                        getPendingPatientDetailsTypeListBySessionIdApi({
                          healthcampSessionId: sessionId,
                          patientId: patientId,
                        } as GetPendingPatientDetailsTypeListBySessionIdBody),
                      );
                    }}
                  />
                </Grid>
                <Grid item>
                  {vitalCollectionStatus?.getVitalCollectionStatusRes?.status !== undefined ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => statusActiveInactive()}
                      sx={{ textTransform: "none" }}
                    >
                      {vitalCollectionStatus?.getVitalCollectionStatusRes?.status !== undefined
                        ? vitalCollectionStatus?.getVitalCollectionStatusRes?.status
                        : ""}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <ButtonGroup
                    variant="outlined"
                    disableElevation
                    aria-label="Disabled button group"
                  >
                    <Button onClick={() => setShowAddExistingPatientDetailsDialog(true)}>
                      Add Existing Patient Details
                    </Button>
                    <Button onClick={() => updateDialogState()}>Add New Patient Details</Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {pendingPatientDetailsTypeListRes?.getPendingPatientDetailsTypeListBySessionIdRes
                  ?.mandatoryPatientDeatilTypeList !== undefined &&
                pendingPatientDetailsTypeListRes?.getPendingPatientDetailsTypeListBySessionIdRes
                  ?.mandatoryPatientDeatilTypeList.length ? (
                  <React.Fragment>
                    {pendingPatientDetailsTypeListRes?.getPendingPatientDetailsTypeListBySessionIdRes?.mandatoryPatientDeatilTypeList?.map(
                      (mandatoryList: MandatoryPatientDeatilTypeList) => (
                        <Grid item>
                          {mandatoryList?.id && (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ borderRadius: "15", textTransform: "none" }}
                              onClick={() => {
                                openAddDetailsDialog({
                                  value: mandatoryList?.id,
                                  label: mandatoryList?.displayValue,
                                });
                              }}
                            >
                              {mandatoryList.displayValue}
                            </Button>
                          )}
                        </Grid>
                      ),
                    )}
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {dataGridArray && dataGridArray.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
      {docViwerActive ? (
        <DocumentViewer
          dialogState={docViwerActive}
          dialogClose={dialogClose}
          value={selectedItem}
          page={page}
          // toggleState={toggleState}
        />
      ) : null}
      {showAddDataPointDialog ? (
        <AddPatientDetailsDialog
          sessionId={sessionId}
          addDataPointDialogEnable={showAddDataPointDialog}
          addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
          pendingDisplayValue={pendingDisplayValue}
          updatePendingDisplayValue={updatePendingDisplayValueFromChild}
        />
      ) : null}
      {showAddExistingPatientDetailsDialog ? (
        <AddExistingPatientDetailsBySessionIdDialog
          filterDialogEnable={showAddExistingPatientDetailsDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          healthcampSessionId={sessionId}
          patientid={patientId}
        />
      ) : null}
      {addPatientDetailsInCompetitionRes?.successMsg !== "" ? (
        <MWSnackbar
          msg={addPatientDetailsInCompetitionRes?.successMsg}
          type="success"
          alertClose={updateAddPatientDetailsInCompetitionRes}
        />
      ) : null}
      {statusSuccess ? (
        <MWSnackbar
          msg={statusSuccess}
          type={linkImageToCompetitionMsgType}
          alertClose={checkAlertClose}
        />
      ) : null}

      <MWSnackbar
        msg={updateDataPointSuccess}
        type={linkImageToCompetitionMsgType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
