import { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../../component/MWExceptionList";
import CloseIcon from "@mui/icons-material/Close";
import {
  clearEditPatientTeamAPIRes,
  editPatientTeam,
  editPatientTeamAPIResMsg,
} from "redux/EditPatientTeam/API";
import { EditPatientTeamBody } from "redux/EditPatientTeam/Model";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { PatientTeamBody } from "redux/GetPatientTeamByPatientID/Model";
import { getPatientTeamById } from "redux/GetPatientTeamByPatientID/API";
import {
  GetPatientListByTeamIdBody,
  PatientList,
} from "redux/CorporatePackage/PatientTeam/PatientDetailsListByProfileId/Model";
import { getPatientListByTeamIdApi } from "redux/CorporatePackage/PatientTeam/PatientDetailsListByProfileId/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import AdminLoader from "pages/AdminLoader";
import MWSnackbar from "component/MWSnackbar";

export default function PatientTeamListDetails() {
  const dispatch = useDispatch();
  const { patientDetailsID } = useParams() as {
    patientDetailsID: string;
  };
  const history = useHistory();
  // Call Store
  const patientTeamListDetailsValue = useSelector(
    (state: AppState) => state.getPatientDetailsByIdRes,
  );
  // call Patient list Store
  const patientListByIdValues = useSelector(
    (state: AppState) => state.getPatientListByProfileIdValue,
  );
  const [page, setPage] = useState(1);
  const [patientListCount, setPatientListCount] = useState("");
  const [pageListCount, setPageListCount] = useState("");
  const [patientTeamPhone, setpatientTeamPhone] = useState("");
  const [patientTeamEmail, setpatientTeamEmail] = useState("");
  const [patientTeamFirstName, setpatientTeamFirstName] = useState("");
  const [patientTeamLastName, setpatientTeamLastName] = useState("");
  const [patientTeamEmailPhone, setpatientTeamEmailPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [profileId, setProfileId] = useState("");
  const [phoneModalActive, setPhoneModalActive] = useState(false);
  const [patientListById, setPatientListById] = useState([] as any[]);
  const truncate = (str: any) => {
    return str.length > 20 ? str.substring(0, 25) + "..." : str;
  };
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    let apiBody = {
      patientTeamProfileId: profileId,
    } as GetPatientListByTeamIdBody;
    dispatch(getPatientListByTeamIdApi(apiBody, value));
  };
  function gotoAddPatient() {
    localStorage.setItem("PatientTeamID", String(patientTeamEmailPhone));
    history.push(`/demographicdetails`);
  }

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  useEffect(() => {
    const addExistingMemberBody = {
      patientId: patientDetailsID,
    } as PatientTeamBody;
    dispatch(getPatientTeamById(addExistingMemberBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const closeModal = () => {
    setPhoneModalActive((phoneModalActive) => !phoneModalActive);
    setpatientTeamEmailPhone("");
    setErrorPhone("");
  };

  function gotoEditPatient() {
    setPhoneModalActive((phoneModalActive) => !phoneModalActive);
  }

  const phoneNumberSubmit = () => {
    if (patientTeamEmailPhone === "") {
      setErrorPhone("Phone Number is empty");
    } else if (
      //eslint-disable-next-line
      new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/).test(
        patientTeamEmailPhone.toString(),
      ) === false
    ) {
      setErrorPhone("Please enter valid 10 digit phone number");
    } else {
      setErrorPhone("");
      let editBody = {
        destinationPatientTeamProfileId: String(patientDetailsID),
        phoneNumber: Number(patientTeamEmailPhone),
      } as EditPatientTeamBody;
      dispatch(editPatientTeam(editBody));
    }
  };

  const reloadPatientList = () => {
    let apiBody = {
      patientTeamProfileId: profileId,
    } as GetPatientListByTeamIdBody;
    dispatch(getPatientListByTeamIdApi(apiBody, 1));
  };
  useEffect(() => {
    dispatch(toggleAppBarLoading(patientTeamListDetailsValue?.loading));
    if (patientTeamListDetailsValue!.getPatientRes !== undefined) {
      if (patientTeamListDetailsValue.getPatientRes.patientTeam !== undefined) {
        if (patientTeamListDetailsValue.getPatientRes.patientTeam.firstname !== undefined) {
          setpatientTeamFirstName(patientTeamListDetailsValue.getPatientRes.patientTeam.firstname);
          setpatientTeamLastName(patientTeamListDetailsValue.getPatientRes.patientTeam.lastname);
          setpatientTeamPhone(patientTeamListDetailsValue.getPatientRes!.patientTeam.phone);
          setpatientTeamEmail(patientTeamListDetailsValue.getPatientRes.patientTeam.email);
        }
        if (patientTeamListDetailsValue.getPatientRes.patientTeam.profileId !== undefined) {
          setProfileId(String(patientTeamListDetailsValue.getPatientRes.patientTeam.profileId));
          let profileApiBody = {
            patientTeamProfileId: String(
              patientTeamListDetailsValue.getPatientRes.patientTeam.profileId,
            ),
          } as GetPatientListByTeamIdBody;
          dispatch(getPatientListByTeamIdApi(profileApiBody, 1));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientTeamListDetailsValue]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(patientListByIdValues?.loading));
    patientListByIdValues?.getPatientListByTeamIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientListByIdValues?.getPatientListByTeamIdRes?.numberOfPages))
      : setPageListCount("0");
    patientListByIdValues?.getPatientListByTeamIdRes?.count !== undefined
      ? setPatientListCount(String(patientListByIdValues?.getPatientListByTeamIdRes?.count))
      : setPatientListCount("0");
    if (patientListByIdValues?.getPatientListByTeamIdRes?.patientList !== undefined) {
      let ptList = patientListByIdValues?.getPatientListByTeamIdRes?.patientList.map(
        (element: PatientList) => ({
          id: element.id,
          firstName: element?.firstname !== undefined ? element?.firstname : "",
          lastName: element?.lastname !== undefined ? element?.lastname : "",
          dob: dayjs(element.dob).format("MMM D, YYYY"),
          patientRelationshipWithOwner:
            element.patientRelationshipWithOwner?.displayValue !== undefined
              ? element.patientRelationshipWithOwner?.displayValue
              : "",
          gender: element?.gender.label !== undefined ? element?.gender?.label : "",
          bloodGroup: element?.bloodGroup !== undefined ? element.bloodGroup?.label : "",
          description: element?.description !== undefined ? truncate(element.description) : "",
        }),
      );
      setPatientListById(ptList);
    } else {
      setPatientListById([] as any[]);
      setPageListCount(String(patientListByIdValues?.getPatientListByTeamIdRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListByIdValues]);

  // call Patient list Store
  const editPatientTeamRes = useSelector((state: AppState) => state.editPatientTeamRes);
  const [updatePatientTeamMsg, setUpdatePatientTeamMsg] = useState("");
  const [updatePatientTeamMsgType, setUpdatePatientTeamMsgType] = useState<AlertColor>("success");
  useEffect(() => {
    if (editPatientTeamRes?.successMsg !== "") {
      setUpdatePatientTeamMsgType("success");
      setUpdatePatientTeamMsg(editPatientTeamRes?.successMsg);
      const addExistingMemberBody = {
        patientId: patientDetailsID,
      } as PatientTeamBody;
      dispatch(getPatientTeamById(addExistingMemberBody));
    }
    if (editPatientTeamRes?.errorMsg !== "") {
      setUpdatePatientTeamMsgType("error");
      setUpdatePatientTeamMsg(editPatientTeamRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatientTeamRes]);

  const closeUpdatePatientTeam = (passedVal: boolean) => {
    if (passedVal) {
      setUpdatePatientTeamMsg("");
      dispatch(clearEditPatientTeamAPIRes());
    }
  };

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "patientRelationshipWithOwner",
      headerName: "Relationship",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "bloodGroup",
      headerName: "Blood Group",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const patientTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Patient Team Details
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={() => gotoEditPatient()}
                  >
                    Edit Patient Team
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={() => gotoAddPatient()}
                  >
                    Add Patient
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {patientTeamListDetailsValue?.getPatientRes?.patientTeam !== undefined ? (
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Name&nbsp;:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Typography variant="h6">
                  {patientTeamFirstName + " " + patientTeamLastName}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Email&nbsp;:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {patientTeamEmail}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Phone Number&nbsp;:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {patientTeamPhone}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const patientListTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6">Patient List</Typography>
                </Grid>
                <Grid item>
                  <Chip label={patientListCount} variant="outlined" size="small" />
                </Grid>
                <Grid item>
                  <RefreshIcon
                    sx={{ mt: 1, cursor: "pointer" }}
                    onClick={() => {
                      reloadPatientList();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {patientListByIdValues?.getPatientListByTeamIdRes?.patientList &&
        patientListByIdValues?.getPatientListByTeamIdRes?.patientList.length ? (
          <DataGrid
            rows={patientListById}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const editPhoneModalBody = (
    <Dialog
      open={phoneModalActive}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      {editPatientTeamRes?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Edit Phone Number</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <TextField
            label="Phone Number"
            placeholder="Please enter your Phone Number here"
            value={patientTeamEmailPhone}
            error={errorPhone === "" ? false : true}
            onChange={(e) => {
              setpatientTeamEmailPhone(e.target.value);
            }}
            id="phoneNumberID"
            fullWidth
            multiline
            helperText={errorPhone === "" ? "Please provide phone number" : errorPhone}
          />
          {updatePatientTeamMsg !== "" && updatePatientTeamMsgType !== "error" && (
            <Box my={2}>
              <Alert
                severity={updatePatientTeamMsgType}
                onClose={() => {
                  setUpdatePatientTeamMsg("");
                  dispatch(editPatientTeamAPIResMsg());
                }}
              >
                {updatePatientTeamMsg}
              </Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={phoneNumberSubmit} sx={{ textTransform: "none" }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {patientTable}
        {patientListTable}
      </Stack>
      {editPhoneModalBody}
      {updatePatientTeamMsg !== "" && updatePatientTeamMsgType !== "error" && (
        <MWSnackbar
          msg={updatePatientTeamMsg}
          type={updatePatientTeamMsgType}
          alertClose={closeUpdatePatientTeam}
        />
      )}
    </Box>
  );
}
