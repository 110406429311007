import { useState, useEffect } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../component/MWExceptionList";
export default function ProviderOrganizationReportList() {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */

  // call Store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportListById = {
    // eslint-disable-next-line no-sparse-arrays
    reportList: [
      {
        id: 1,
        report_name: "URINARY PROTEIN TO CREATININE RATIO",
      },
      {
        id: 2,
        report_name: "COMPLETE BLOOD COUNT",
      },
      {
        id: 3,
        report_name: "OBSTETRICAL ULTRASONOGRAPHY",
      },
      {
        id: 4,
        report_name: "DIGITAL RADIOGRAPH OF BOTH ANKLE WITH LEG IN AP & LATERAL VIEW",
      },
      {
        id: 5,
        report_name: "DIGITAL RADIOGRAPH OF BOTH KNEE IN AP & LATERAL VIEW",
      },
      {
        id: 6,
        report_name: "DIGITAL RADIOGRAPH OF CERVICAL SPINE IN AP & LATERAL VIEW",
      },
      {
        id: 7,
        report_name: "DIGITAL RADIOGRAPH OF PELVIS WITH BOTH HIP IN AP VIEW",
      },
      {
        id: 8,
        report_name: "CLINICAL MICROBIOLOGY TEST REPORT URINE FOR CULTURE",
      },
    ],
    status: 1,
  };

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (reportListById?.reportList !== undefined) {
      let dataPointArr = reportListById?.reportList.map((element: any) => ({
        id: element.id,
        report_name: element.report_name,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportListById]);

  const columns: GridColDef[] = [
    {
      field: "report_name",
      headerName: "Diagnostic Test Name",
      editable: false,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];

  const StaffListOfOrganization = (
    <Card>
      <CardContent>
        <Box>
          {reportListById?.reportList && reportListById?.reportList.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{StaffListOfOrganization}</Box>;
}
