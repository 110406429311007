import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import AdminLoader from "pages/AdminLoader";
import MWPageTitle from "component/MWPageTitle";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "component/MWExceptionList";
import dayjs from "dayjs";
import { getPatientDataDetailsList } from "redux/patientDataDetails/patientDataPointListByPatientID/API";
import { NameGenerate } from "component/Utility";
import { getDemographicsDetailsByPtId } from "redux/DemographicsDetails/GetPatientDemographicsDetails/API";
import { PatientDemographicsReq } from "redux/Patient/DemographicsDetails/Model";
import { AddExistingPatientDetailsBySessionIdBody } from "redux/AddExistingPatientDetailsBySession/Model";
import { addExistingPatientDetailsBySessionIdApiCall, clearAddExistingPatientDetailsBySessionIdAPIRes } from "redux/AddExistingPatientDetailsBySession/API";

type Props = {
  filterDialogEnable?: boolean | any;
  filterDialogUpdateState?: boolean | any;
  healthcampSessionId?: string | any;
  patientid?: string | any;
};

export default function AddExistingPatientDetailsBySessionIdDialog({
  filterDialogEnable,
  filterDialogUpdateState,
  healthcampSessionId,
  patientid,
}: Props) {
  console.log(healthcampSessionId);
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    filterDialogUpdateState(false);
    dispatch(clearAddExistingPatientDetailsBySessionIdAPIRes());
  };
  const addExistingPatientResponse = useSelector(
    (state: AppState) => state.addExistingPatientDetailsValue,
  );
  const patientPointDataList = useSelector((state: AppState) => state.getPatientDataDetailsRes);
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("0");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageTitle, setPageTitle] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDataDetailsList(patientid, value));
  };

  useEffect(() => {
    if (addExistingPatientResponse?.successMsg !== "") {
      handleDialogClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExistingPatientResponse]);
  const patientFullName = useSelector(
    (state: AppState) =>
      state.getDemographicsDetailsByPtId?.getDemographicsDetailsByPtIdRes?.personalDetails,
  );
  useEffect(() => {
    if (patientFullName?.firstname !== undefined && patientFullName?.lastname !== undefined) {
      setPageTitle(
        "Patient Details List of " +
          NameGenerate.changeName(
            patientid,
            patientFullName?.firstname + " " + patientFullName?.lastname,
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientFullName]);

  React.useEffect(() => {
    setDialogState(filterDialogEnable);
    if (patientid) {
      dispatch(getPatientDataDetailsList(patientid, page));
      dispatch(
        getDemographicsDetailsByPtId({
          healthRecordId: patientid,
        } as PatientDemographicsReq),
      );
    }
    dispatch(getPatientDataDetailsList(patientid, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDialogEnable]);

  useEffect(() => {
    patientPointDataList?.pageCount !== undefined
      ? setPageListCount(String(patientPointDataList?.pageCount))
      : setPageListCount("0");

    patientPointDataList?.getPatientDataDetailsRes?.count !== undefined
      ? setListCount(String(patientPointDataList?.getPatientDataDetailsRes?.count))
      : setListCount("0");

    if (patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList !== undefined) {
      let dataPointArr = patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList.map(
        (element: any) => ({
          id: element.id,
          patientDetailsId: element.id,
          description: element.description,
          startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
          date: element.startDate,
          image:
            element?.patientDocument?.document !== undefined
              ? element?.patientDocument?.document
              : "",
          imageId:
            element?.patientDocument?.id !== undefined
              ? String(element?.patientDocument?.id)
              : undefined,
        }),
      );
      setDataGridArray(dataPointArr);
    } else {
      setDataGridArray([] as any[]);
      setPageListCount(String(patientPointDataList?.pageCount));
    }
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Patient details I'd",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "patientDetailsId",
      headerName: "Action",
      width: 50,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            addExistingPatientSubmit(params.row.patientDetailsId);
          }}
          sx={{ textTransform: "none" }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            Add Existing Patient
          </span>
        </Button>
      ),
    },
  ];

  const addExistingPatientSubmit = (element: string) => {
    const addExistingPatientBody = {
      healthcampSessionId: healthcampSessionId!,
      patientDetailId: element,
    } as AddExistingPatientDetailsBySessionIdBody;
    dispatch(addExistingPatientDetailsBySessionIdApiCall(addExistingPatientBody));
  };

  return (
    <div>
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        fullScreen
      >
        {addExistingPatientResponse?.loading ? <AdminLoader /> : null}
        <DialogTitle id="customized-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Search Patient Details</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Card>
            <CardContent>
              <Box sx={{ mb: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  my={1}
                >
                  <Grid item>
                    <MWPageTitle title={pageTitle} enableCount={true} count={listCount} />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography>Page: {page}</Typography>
                      </Grid>
                      <Grid item>
                        <Pagination
                          color="primary"
                          count={Number(pageListCount) || 0}
                          page={page}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
              <Box>
                {patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList &&
                patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList.length ? (
                  <DataGrid
                    rows={dataGridArray}
                    columns={columns}
                    disableSelectionOnClick
                    hideFooter
                    hideFooterPagination
                    experimentalFeatures={{ newEditingApi: true }}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                ) : (
                  <MWExceptionList />
                )}
              </Box>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          {/* <Button
            autoFocus
            onClick={() => addSession()}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
