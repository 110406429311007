import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import { Pagination, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../component/MWExceptionList";
import { getInactiveCaseListValue } from "../../redux/GetInactiveCaseList/API";
import { PatientCaseList } from "../../redux/GetInactiveCaseList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function InactiveAllCaseList() {
  const dispatch = useDispatch();
  const history = useHistory();
  function gotoDetails(patientId: number, caseId: number) {
    history.push(`/casedetails/${patientId}/${caseId}`);
  }
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getInactiveCaseListValue(value, 0));
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  // encounter list api call ...
  useEffect(() => {
    dispatch(getInactiveCaseListValue(page, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const inactiveCaseListValue = useSelector((state: AppState) => state.getInactiveCaseListValue);
  const inactiveValues = inactiveCaseListValue?.getInactiveCaseListRes?.patientCaseList;
  useEffect(() => {
    dispatch(toggleAppBarLoading(inactiveCaseListValue?.loading));
    inactiveCaseListValue?.getInactiveCaseListRes?.numberOfPages !== undefined
      ? setPageListCount(String(inactiveCaseListValue?.getInactiveCaseListRes?.numberOfPages))
      : setPageListCount("0");
    if (inactiveCaseListValue?.getInactiveCaseListRes?.patientCaseList !== undefined) {
      let dataPointArr = inactiveCaseListValue?.getInactiveCaseListRes?.patientCaseList.map(
        (element: PatientCaseList) => ({
          id: element.caseId,
          caseId: element.caseId,
          patientId: element.patientId,
          patientName: element.patientName,
          WhyWant2ndOpinion: element.whyWant2ndOpinion,
          reasons: element.secondOpinionReasons,
          consultType: element.consultationType,
          docTeamType: element.doctorTeamType,
        }),
      );
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(inactiveCaseListValue?.getInactiveCaseListRes?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveCaseListValue]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.row.patientId, params.row.caseId);
          }}
          variant="outlined"
        >
          View
        </Button>
      ),
    },
    {
      field: "caseId",
      headerName: "Case Id",
      minWidth: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "patientName",
      headerName: "Patient name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "WhyWant2ndOpinion",
      headerName: "Why Want 2nd Opinion",
      editable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "reasons",
      headerName: "Second Opinion Reasons",
      type: "string",
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "consultType",
      headerName: "Consultation Type",
      sortable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "docTeamType",
      headerName: "DoctorTeamType",
      minWidth: 150,
      align: "left",
      flex: 1,
    },
  ];

  const inactiveCaseListPage = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {inactiveValues && inactiveValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return <Box>{inactiveCaseListPage}</Box>;
}
