import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  AlertColor,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWExceptionList from "component/MWExceptionList";
import { executiveList } from "redux/Executive/GetExecutiveList/API";
import UpdateExecutive from "./UpdateExecutive";
import MWSnackbar from "component/MWSnackbar";
import { clearRemoveExecutiveAPIRes } from "redux/Executive/RemoveExecutive/API";
import { clearAddExecutiveAPIRes } from "redux/Executive/AddExecutive/API";
import { clearEditExecutiveAPIRes } from "redux/Executive/EditExecutive/API";

type Props = {
  executiveListLoader?: boolean | any;
  listCount?: string | any;
};

export default function ExecutiveListPage({ executiveListLoader, listCount }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);

  // Go to Executive Details Page
  const gotoExecutiveDetails = (executiveId: any) => {
    history.push(`/executivetaskdetails/${executiveId}`);
  };
  // Get Executive List API
  useEffect(() => {
    dispatch(executiveList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executiveListRes = useSelector((state: AppState) => state.executiveListRes);
  const [selectedExecutive, setSelectiveExecutive] = useState({} as any);
  const [enableUpdateExecutiveDetailsDialog, setEnableUpdateExecutiveDetailsDialog] =
    useState(false);
  const closeUpdateExecutiveDetailsDialog = (passedVal: boolean) => {
    setEnableUpdateExecutiveDetailsDialog(passedVal);
  };
  const updateExecutiveDetails = (passedVal: any) => {
    setSelectiveExecutive(passedVal);
  };

  useEffect(() => {
    executiveListLoader(executiveListRes?.loading);
    executiveListRes?.executiveListRes?.executiveList !== undefined
      ? listCount(String(executiveListRes?.executiveListRes?.executiveList?.length))
      : listCount("0");
    if (executiveListRes?.executiveListRes?.executiveList !== undefined) {
      let dataPointArr = executiveListRes?.executiveListRes?.executiveList.map((element: any) => ({
        id: element.id,
        userId: element.userId,
        executiveEmail: element.email,
        executiveFirstName: element.first_name,
        executiveLastName: element.last_name,
        executivePhoneNumber: element.phoneNumber,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveListRes]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 100,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoExecutiveDetails(params.row.id);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Task
        </Button>
      ),
    },
    {
      field: "executiveFirstName",
      headerName: "First Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "executiveLastName",
      headerName: "Last Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "executiveEmail",
      headerName: "Executive Email",
      type: "string",
      minWidth: 220,
      align: "left",
      flex: 1,
    },
    {
      field: "executivePhoneNumber",
      headerName: "Phone Number",
      sortable: true,
      minWidth: 130,
      align: "left",
    },

    {
      field: "userId",
      headerName: "Edit",
      minWidth: 100,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          sx={{ textTransform: "none" }}
          onClick={() => {
            setSelectiveExecutive(params.row);
            setEnableUpdateExecutiveDetailsDialog(true);
          }}
          variant="contained"
        >
          Edit
        </Button>
      ),
    },
  ];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Executive List Table
  const ExecutiveTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
              <Button
                variant="contained"
                fullWidth
                onClick={() => setEnableUpdateExecutiveDetailsDialog(true)}
                sx={{ textTransform: "none" }}
              >
                Add Executive
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent sx={{ height: fullScreen ? `calc(100vh - 34vh)` : `calc(100vh - 39vh)` }}>
        {dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const addExecutiveRes = useSelector((state: AppState) => state.addExecutiveRes);
  const [addExecutiveMsg, setAddExecutiveMsg] = useState("");
  const [addExecutiveMsgType, setAddExecutiveMsgType] = useState<AlertColor>("success");
  const closeAddExecutiveAlert = (passedVal: boolean) => {
    if (passedVal) {
      setAddExecutiveMsg("");
      dispatch(clearAddExecutiveAPIRes());
    }
  };

  useEffect(() => {
    if (addExecutiveRes?.successMsg !== "") {
      setAddExecutiveMsgType("success");
      setAddExecutiveMsg(addExecutiveRes?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addExecutiveRes]);

  const editExecutiveRes = useSelector((state: AppState) => state.editExecutiveRes);
  const [editExecutiveMsg, setEditExecutiveMsg] = useState("");
  const [editExecutiveMsgType, setEditExecutiveMsgType] = useState<AlertColor>("success");
  const closeEditExecutiveAlert = (passedVal: boolean) => {
    if (passedVal) {
      setEditExecutiveMsg("");
      dispatch(clearEditExecutiveAPIRes());
    }
  };

  useEffect(() => {
    if (editExecutiveRes?.successMsg !== "") {
      setEditExecutiveMsgType("success");
      setEditExecutiveMsg(editExecutiveRes?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editExecutiveRes]);

  const removeExecutiveRes = useSelector((state: AppState) => state.removeExecutiveRes);
  const [removeExecutiveMsg, setRemoveExecutiveMsg] = useState("");
  const [removeExecutiveMsgType, setRemoveExecutiveMsgType] = useState<AlertColor>("success");
  const closeRemoveExecutiveAlert = (passedVal: boolean) => {
    if (passedVal) {
      setRemoveExecutiveMsg("");
      dispatch(clearRemoveExecutiveAPIRes());
    }
  };

  useEffect(() => {
    if (removeExecutiveRes?.successMsg !== "") {
      setRemoveExecutiveMsgType("success");
      setRemoveExecutiveMsg(removeExecutiveRes?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeExecutiveRes]);

  return (
    <Box>
      {ExecutiveTable}
      <UpdateExecutive
        enableDialog={enableUpdateExecutiveDetailsDialog}
        updateDialogState={closeUpdateExecutiveDetailsDialog}
        executiveDetails={selectedExecutive}
        updateExecutiveDetails={updateExecutiveDetails}
      />
      {addExecutiveMsg !== "" && (
        <MWSnackbar
          msg={addExecutiveMsg}
          type={addExecutiveMsgType}
          alertClose={closeAddExecutiveAlert}
        />
      )}
      {editExecutiveMsg !== "" && (
        <MWSnackbar
          msg={editExecutiveMsg}
          type={editExecutiveMsgType}
          alertClose={closeEditExecutiveAlert}
        />
      )}
      {removeExecutiveMsg !== "" && (
        <MWSnackbar
          msg={removeExecutiveMsg}
          type={removeExecutiveMsgType}
          alertClose={closeRemoveExecutiveAlert}
        />
      )}
    </Box>
  );
}
