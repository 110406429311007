import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getDoctorOnboardingListValue } from "../../redux/effects/ApiCall";
import { drApproveOrReject } from "../../redux/DoctorOnboarding/ApiCall";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import AddDoctorDialog from "./AddDoctorDialog";
import MWToast from "../../component/MWToast";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function DoctorsOnboardingRequest() {
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [listCount, setListCount] = React.useState("");

  // Call Add chief complaint store
  //const AddDrValue = useSelector((state: AppState) => state.addDoctorvalue);
  const AddDrMessage = useSelector((state: AppState) => state.addDoctorvalue.message);
  const AddDrStatus = useSelector((state: AppState) => state.addDoctorvalue.status);

  // Add dr success toast
  const [successAddDrToastActive] = useState(false);

  const toastAddDrSuccessMarkup = successAddDrToastActive ? (
    <MWToast message={AddDrMessage} />
  ) : null;

  // Add dr error toast
  const [addDrErrorToastActive, setSddDrErrorToastActive] = useState(false);
  console.log(setSddDrErrorToastActive);
  const toastAddDrErrorMarkup = addDrErrorToastActive ? <MWToast message={AddDrMessage} /> : null;

  // Call Dr Approve Reject store
  const drApproveRejectMessage = useSelector((state: AppState) => state.addDoctorvalue.message);
  const drApproveRejectStatus = useSelector((state: AppState) => state.addDoctorvalue.status);

  // dr Approve Reject  success toast
  const [successDrApproveRejectToastActive, setSuccessDrApproveRejectToastActive] = useState(false);

  const toastDrApproveRejectSuccessMarkup = successDrApproveRejectToastActive ? (
    <MWToast message={drApproveRejectMessage} />
  ) : null;

  // dr Approve Reject error toast
  const [errorDrApproveRejectToastActive, setErrorDrApproveRejectToastActive] = useState(false);
  const handleErrorDrApproveRejectToastChange = () => {
    setErrorDrApproveRejectToastActive(
      (errorDrApproveRejectToastActive) => !errorDrApproveRejectToastActive,
    );
  };
  console.log(handleErrorDrApproveRejectToastChange);
  const toastErrorDrApproveRejectToastMarkup = errorDrApproveRejectToastActive ? (
    <MWToast message={drApproveRejectMessage} />
  ) : null;

  // Get Doctor Onboarding Request List API
  const doctorOnBoardingApproveOrReject = (doctorId: any, approveOrreject: any) => {
    dispatch(drApproveOrReject(doctorId, approveOrreject));
    setSuccessDrApproveRejectToastActive(
      (successDrApproveRejectToastActive) => !successDrApproveRejectToastActive,
    );
    setErrorDrApproveRejectToastActive(
      (errorDrApproveRejectToastActive) => !errorDrApproveRejectToastActive,
    );
  };

  useEffect(() => {
    dispatch(getDoctorOnboardingListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const AdminDoctorRequestList = useSelector((state: AppState) => state.doctorRequestValue);
  const allDoctorRequestValues = AdminDoctorRequestList.doctorRequestValue;
  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminDoctorRequestList?.loading));
    allDoctorRequestValues?.length !== undefined
      ? setListCount(String(allDoctorRequestValues?.length))
      : setListCount("0");
    if (allDoctorRequestValues !== undefined) {
      let dataPointArr = allDoctorRequestValues.map((element: any) => ({
        id: element.id,
        doctorId: element.id,
        name: element.fullName,
        qualification: element.qualification,
        specialty: element.specialty,
        yearsOfExperience: element.yearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminDoctorRequestList]);
  const doctorOnboardingRequestListApi = () => {
    dispatch(getDoctorOnboardingListValue());
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      editable: false,
      minWidth: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      editable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "specialty",
      headerName: "Speciality",
      type: "string",
      minWidth: 250,
      align: "left",
      flex: 1,
    },
    {
      field: "yearsOfExperience",
      headerName: "Years of Experience",
      sortable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "doctorId",
      headerName: "Approve",
      minWidth: 150,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CheckIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "True");
          }}
        >
          Approve
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Reject",
      minWidth: 150,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<CloseIcon />}
          onClick={() => {
            doctorOnBoardingApproveOrReject(params.row.doctorId, "False");
          }}
        >
          Reject
        </Button>
      ),
    },
  ];

  const [showAddDoctorDialog, setShowAddDoctorDialog] = React.useState(false);
  const updateDoctorDialogState = () => {
    setShowAddDoctorDialog(true);
  };
  const updateAddDoctorDialogValFromChild = (passedVal: boolean) => {
    setShowAddDoctorDialog(passedVal);
  };
  // Doctor request Table
  const doctorOnBoardingRequestList = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                title="Doctor's On-Boarding Request List"
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={doctorOnboardingRequestListApi}
              />
            </Grid>
            <Grid item mt={2}>
              <Button
                variant="contained"
                onClick={updateDoctorDialogState}
                sx={{ textTransform: "none" }}
              >
                Add Doctor
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {allDoctorRequestValues && allDoctorRequestValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {doctorOnBoardingRequestList}
      {AddDrStatus! === 1 ? toastAddDrSuccessMarkup : ""}
      {AddDrStatus! === -1 ? toastAddDrErrorMarkup : ""}
      {drApproveRejectStatus! === 1 ? toastDrApproveRejectSuccessMarkup : ""}
      {drApproveRejectStatus! === 1 ? toastErrorDrApproveRejectToastMarkup : ""}
      {showAddDoctorDialog ? (
        <AddDoctorDialog
          addDoctorDialogEnable={showAddDoctorDialog}
          addDoctorDialogUpdateState={updateAddDoctorDialogValFromChild}
        />
      ) : null}
    </Box>
  );
}
