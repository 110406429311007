import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertColor, Box, Button, Chip, Grid, Pagination, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import MWExceptionList from "component/MWExceptionList";
import { AppState } from "redux/store/Store";
import React from "react";
import { DataCurationProblemList } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/Model";
import dayjs from "dayjs";
import { getDataCurationProblemListApi } from "redux/HealthcampSessionList/DataCurationProblemListByInstanceId/API";
import AddDataCurationDialog from "./AddDataCurationDialog";
import MWSnackbar from "component/MWSnackbar";
import { clearAddDataCurationProblemAPIRes } from "redux/HealthcampSessionList/AddDataCuration/API";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";

export default function DataCurationProblemListComponent() {
  const dispatch = useDispatch();
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [dataCurationValue, setDataCurationValue] = React.useState("");
  const [dataCurationListCount, setDataCurationListCount] = React.useState("");
  const [addDataCurationProblemMessage, setAddDataCurationProblemMessage] = useState("");
  const [addDataCurationProblemMessageType, setAddDataCurationProblemMessageType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  // call Store
  const dataCurationProblemListRes = useSelector(
    (state: AppState) => state.getDataCurationProblemListValue,
  );
  const addDataCurationProbResponse = useSelector(
    (state: AppState) => state.addDataCurationProbValues,
  );
  const editDataCurationProbResponse = useSelector(
    (state: AppState) => state.editDataCurationResult,
  );
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    //patientDetailsLoader(filterpatientDetailsByObservationList?.loading);
    dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (dataCurationProblemListRes?.getDataCurationProblemListRes?.count !== undefined) {
      setDataCurationListCount(
        String(dataCurationProblemListRes?.getDataCurationProblemListRes?.count),
      );

      let dataPointArr =
        dataCurationProblemListRes?.getDataCurationProblemListRes?.dataCurationProblemList.map(
          (element: DataCurationProblemList) => ({
            id: element?.id,
            problemType: element.problemType,
            description: element.description,
            targetModelId: element.targetModelId,
            sourceModelId: element.sourceModelId,
            sessionAssociated: element.sessionAssociatedId,
            destinationModelId: element.destinationModelId,
            createDate: dayjs(element.createDate).format("MM/DD/YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MM/DD/YYYY"),
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(dataCurationProblemListRes?.getDataCurationProblemListRes?.numberOfPages),
      );
      setDataCurationListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurationProblemListRes]);
  useEffect(() => {
    let apiBody = {
      healthcampSessionId: sessionId,
    };
    dispatch(getDataCurationProblemListApi(apiBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (addDataCurationProbResponse?.successMsg !== "") {
      setAddDataCurationProblemMessageType("success");
      setAddDataCurationProblemMessage(addDataCurationProbResponse?.successMsg);
      if (addDataCurationProbResponse?.errorMsg !== "") {
        setAddDataCurationProblemMessageType("error");
        setAddDataCurationProblemMessage(addDataCurationProbResponse?.errorMsg);
      }
      let apiBody = {
        healthcampSessionId: sessionId,
      };
      dispatch(getDataCurationProblemListApi(apiBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataCurationProbResponse]);
  useEffect(() => {
    if (editDataCurationProbResponse?.successMsg !== "") {
      setAddDataCurationProblemMessageType("success");
      setAddDataCurationProblemMessage(editDataCurationProbResponse?.successMsg);
      if (editDataCurationProbResponse?.errorMsg !== "") {
        setAddDataCurationProblemMessageType("error");
        setAddDataCurationProblemMessage(editDataCurationProbResponse?.errorMsg);
      }
      let apiBody = {
        healthcampSessionId: sessionId,
      };
      dispatch(getDataCurationProblemListApi(apiBody, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDataCurationProbResponse]);
  const [showAddDataCurationDialog, setShowAddDataCurationDialog] = useState(false);
  const updateAddDataCurationDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataCurationDialog(passedVal);
  };

  const editDataCuration = (dataCurationId: string) => {
    setDataCurationValue(dataCurationId);
    setShowAddDataCurationDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "problemType",
      headerName: "Problem Type",
      align: "left",
      minWidth: 280,
    },
    {
      field: "description",
      headerName: "Description",
      align: "left",
      flex: 1,
    },
    {
      field: "targetModelId",
      headerName: "Target Id",
      minWidth: 100,
      align: "left",
    },
    {
      field: "sourceModelId",
      headerName: "Source Id",
      minWidth: 100,
      align: "left",
    },
    {
      field: "destinationModelId",
      headerName: "Destination Id",
      minWidth: 100,
      align: "left",
    },
    {
      field: "sessionAssociated",
      headerName: "Session Associated",
      minWidth: 150,
      align: "left",
    },
    {
      field: "createDate",
      headerName: "Create Date",
      minWidth: 120,
      align: "left",
    },
    {
      field: "id",
      headerName: "Action",
      editable: false,
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => {
            editDataCuration(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const reloadList = () => {
    let apiBody = {
      healthcampSessionId: sessionId,
    };
    dispatch(getDataCurationProblemListApi(apiBody, 1));
  };

  const GetDataCurationProblemList = (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6">Data Curation Problem List</Typography>
              </Grid>
              <Grid item>
                <Chip label={dataCurationListCount} variant="outlined" size="small" />
              </Grid>
              <Grid item>
                <RefreshIcon
                  sx={{ mt: 1, cursor: "pointer" }}
                  onClick={() => {
                    reloadList();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    setShowAddDataCurationDialog(true);
                    setDataCurationValue("");
                  }}
                >
                  Add Data Curation
                </Button>
              </Grid>
              <Grid item>
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount)}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
          {dataGridArray && dataGridArray.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const addImgtoComp = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddDataCurationProblemAPIRes());
      setAddDataCurationProblemMessage("");
    }
  };

  return (
    <Box>
      {GetDataCurationProblemList}
      {showAddDataCurationDialog && (
        <AddDataCurationDialog
          filterDialogEnable={showAddDataCurationDialog}
          filterDialogUpdateState={updateAddDataCurationDialogValFromChild}
          dataCurationValue={dataCurationValue}
        />
      )}
      {addDataCurationProblemMessage !== "" ? (
        <MWSnackbar
          msg={addDataCurationProblemMessage}
          type={addDataCurationProblemMessageType}
          alertClose={addImgtoComp}
        />
      ) : null}
    </Box>
  );
}
