import React, { useEffect } from "react";
import { Box, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";

import { getSingleEncounterListApi } from "redux/PatientEncounterListByid/SingleEncounterList/API";
import { EncounterList } from "redux/PatientEncounterListByid/SingleEncounterList/Model";

type Props = {
  encounterListLoader?: boolean | any;
};

export default function SingleEncounterListByPatient({ encounterListLoader }: Props) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getSingleEncounterListApi(value));
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  useEffect(() => {
    dispatch(getSingleEncounterListApi(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const singleEncounterList = useSelector((state: AppState) => state.getSingleEncounterListValue);

  useEffect(() => {
    encounterListLoader(singleEncounterList?.loading);
    singleEncounterList?.getSingleEncounterListRes?.numberOfPages !== undefined
      ? setPageListCount(String(singleEncounterList?.getSingleEncounterListRes?.numberOfPages))
      : setPageListCount("0");
    if (singleEncounterList?.getSingleEncounterListRes?.encounterList !== undefined) {
      let dataPointArr = singleEncounterList?.getSingleEncounterListRes?.encounterList.map(
        (element: EncounterList) => ({
          id: element.id,
          createDate: dayjs(element.createDate).format("DD/MM/YYYY"),
          encounterDate: dayjs(element.encounterDate).format("DD/MM/YYYY"),
          modifiedDate: dayjs(element.modifiedDate).format("DD/MM/YYYY"),
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleEncounterList]);

  const columns: GridColDef[] = [
    {
      field: "createDate",
      headerName: "Encounter Create date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "encounterDate",
      headerName: "Encounter Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
  ];

  const singleEncounterListTable = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {singleEncounterList?.getSingleEncounterListRes?.encounterList &&
        singleEncounterList?.getSingleEncounterListRes?.encounterList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      {singleEncounterList?.loading === true
        ? encounterListLoader(true)
        : encounterListLoader(false)}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {singleEncounterListTable}
        </Stack>
      </Container>
    </Box>
  );
}
