import { useState, useEffect } from "react";
import { Box, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getpatientDetails } from "redux/Patient/DemographicsDetails/ApiCall";
import { PatientDemographicsReq } from "redux/Patient/DemographicsDetails/Model";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { getPatientDetailsListByDuplicateIdApi } from "redux/patientDataDetails/PatientDetailsListByDuplicateId/API";
import { GetPatientDetailsListByDuplicateIdBody } from "redux/patientDataDetails/PatientDetailsListByDuplicateId/Model";

type Props = {
  patientduplicateid?: any;
};

export default function PatientDetailsOfDuplicatePatient({ patientduplicateid }: Props) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getPatientDetailsListByDuplicateIdApi(
        { patientId: patientduplicateid } as GetPatientDetailsListByDuplicateIdBody,
        value,
      ),
    );
  };
  useEffect(() => {
    dispatch(
      getPatientDetailsListByDuplicateIdApi(
        { patientId: patientduplicateid } as GetPatientDetailsListByDuplicateIdBody,
        page,
      ),
    );
    dispatch(
      getpatientDetails({
        healthRecordId: patientduplicateid,
      } as PatientDemographicsReq),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const getDuplicatePatientDetailsList = useSelector(
    (state: AppState) => state.getPatientDetailsByDuplicateIdValues,
  );
  const duplicatePatientDetailsList =
    getDuplicatePatientDetailsList?.getPatientDetailsListByDuplicateIdRes?.patientDetailsList;
  const [duplicatePatientDetailVal, setDuplicatePatientDetailVal] = useState([] as any[]);
  const [selectedItems, setSelectedItems] = React.useState([] as any[]);
  console.log("selectedItems===", selectedItems);

  useEffect(() => {
    getDuplicatePatientDetailsList?.getPatientDetailsListByDuplicateIdRes?.numberOfPages !==
    undefined
      ? setPageListCount(
          String(
            getDuplicatePatientDetailsList?.getPatientDetailsListByDuplicateIdRes?.numberOfPages,
          ),
        )
      : setPageListCount("0");
    if (duplicatePatientDetailsList !== undefined) {
      let ptDetailsList = duplicatePatientDetailsList.map((element: any) => ({
        id: element.id,
        patientId: element.patientId,
        description: element?.description !== undefined ? element.description : "",
      }));
      setDuplicatePatientDetailVal(ptDetailsList);
    } else setDuplicatePatientDetailVal([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDuplicatePatientDetailsList]);

  const duplicatecolumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Duplicate Patient details Id",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 190,
    },
    {
      field: "patientId",
      headerName: "Duplicate Patient Id",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const duplicatePatientdetailsTable = (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {duplicatePatientDetailsList && duplicatePatientDetailsList.length ? (
          <DataGrid
            rows={duplicatePatientDetailVal}
            columns={duplicatecolumns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = duplicatePatientDetailVal.filter((row: any) =>
                selectedIDs.has(row.id),
              );
              const mi = selectedRows.map((item: any) => ({
                patientDetailsId: item.id,
                firstName: item.firstName,
              }));
              setSelectedItems(mi);
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {duplicatePatientdetailsTable}
        </Stack>
      </Container>
    </Box>
  );
}
