import React, { useEffect } from "react";
import { Box, Container, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { getSubscriptionListById } from "redux/PatientSubscriptionListById/API";
import { MedicalWisdomSubscriptionList } from "redux/PatientSubscriptionListById/Model";

type Props = {
  subscriptionListLoader?: boolean | any;
};

export default function SubscriptionListByPatient({
  subscriptionListLoader,
}: Props) {
  const dispatch = useDispatch();
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  useEffect(() => {
    dispatch(getSubscriptionListById());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const subscriptionList = useSelector((state: AppState) => state.getPatientSubscriptionListbyIdValues);

  useEffect(() => {
    subscriptionListLoader(subscriptionList?.loading);
    if (subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList !== undefined) {
      let dataPointArr = subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList.map(
        (element: MedicalWisdomSubscriptionList) => ({
          id: element.id,
          displayName: element.displayName,
          dateCreated: dayjs(element.dateCreated).format("DD/MM/YYYY"),
          monthsValid: element.monthsValid,
          classesValid: element.classesValid,
          isActive: element.isActive
        }),
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionList]);



  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "View",
    //   type: "string",
    //   editable: false,
    //   sortable: true,
    //   disableColumnMenu: true,
    //   align: "left",
    //   minWidth: 80,
    //   renderCell: (params: GridCellParams) => (
    //     <Button
    //       startIcon={<VisibilityIcon />}
    //       onClick={() => {
    //         gotoSubscriptionDetails(params.value);
    //       }}
    //       variant="outlined"
    //       sx={{ textTransform: "none" }}
    //     >
    //       View
    //     </Button>
    //   ),
    // },
    {
      field: "displayName",
      headerName: "Subscription Name",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "dateCreated",
      headerName: "Created Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
    {
      field: "monthsValid",
      headerName: "Valid Month",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
    {
      field: "classesValid",
      headerName: "Valid classes",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
    {
      field: "isActive",
      headerName: "Active Status",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      minWidth: 110,
    },
  ];

  const subscriptionListTable = (
      <Box>
        {subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList  &&
        subscriptionList?.GetSubscriptionListByIdResponse?.medicalWisdomSubscriptionList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
          pt={2}
        >
          {subscriptionListTable}
        </Stack>
      </Container>
    </Box>
  );
}
