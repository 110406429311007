import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/store/Store";
import { Alert, AlertColor, Box, Button, Grid, Pagination, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../../../component/MWExceptionList";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GetAppointmentListHealthcampBody,
  PatientList,
} from "redux/AppointmentListOfHealthcampInstance/Model";
import { getAppointmentListOfHealthcampInstance } from "redux/AppointmentListOfHealthcampInstance/API";
import AddAppoinmentDialog from "./AddAppointmentDialog";
import dayjs from "dayjs";
import { clearAppointmentListOfHealthcampAPIRes } from "redux/AppointmentListOfHealthcampInstance/AddAppoinmentHealthCampInstance/Api";
import AddCheckInDialogBySession from "./AddCheckInDialogBySession";
import MWSnackbar from "component/MWSnackbar";

type Props = {
  appoinmentListLoader?: boolean | any;
};

export default function AppointmentList({ appoinmentListLoader }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [showCheckInDialog, setShowCheckInDialog] = React.useState(false);
  const [patientId, setPatientId] = React.useState("");
  const [patientCheckInMsg, setPatientCheckInMsg] = React.useState("");
  const [checkInMsgType, setCheckInMsgType] =
    useState<AlertColor>("success");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const healthcampAppoinmentListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetAppointmentListHealthcampBody;
    dispatch(getAppointmentListOfHealthcampInstance(healthcampAppoinmentListBody, 1));
  };
  // call Store
  const healthcampInsttanceAppoinmentValue = useSelector(
    (state: AppState) => state.getAppointmentListOfHealthcampInstance,
  );
  const addResponse = useSelector((state: AppState) => state.patientCheckInValues);
  useEffect(() => {
    const healthcampAppoinmentListBody = {
      healthcampInstanceId: instanceHealthcampId,
    } as GetAppointmentListHealthcampBody;
    dispatch(getAppointmentListOfHealthcampInstance(healthcampAppoinmentListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [instanceAppoinmentGridArray, setInstanceAppoinmentGridArray] = useState([] as any[]);
  const [notAdministratorMsg, setNotAdministratorMsg] = useState("");
  const [showAppoinmentDialog, setShowAppointmentDialog] = React.useState(false);
  const updateAppointmentDialogValFromChild = (passedVal: boolean) => {
    setShowAppointmentDialog(passedVal);
  };

  const updateCheckInValFromChild = (checkInVal: boolean) => {
    setShowCheckInDialog(checkInVal);
  };

  useEffect(() => {
    appoinmentListLoader(healthcampInsttanceAppoinmentValue?.loading);
    healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse?.numberOfPages !==
    undefined
      ? setPageListCount(
          String(
            healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse
              ?.numberOfPages,
          ),
        )
      : setPageListCount("0");
    if (
      healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse?.patientList !==
      undefined
    ) {
      let healthcampAppoinmentArr =
        healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse?.patientList.map(
          (element: PatientList) => ({
            patientId: element.patientId,
            checkInId: element.patientId,
            patientName: element.givenName + " " + element.familyName,
            userName: element.userName,
            email: element.email,
            phone: element.phone,
            pincode: element.pincode,
            dob: dayjs(element.dob).format("MMMM D, YYYY"),
            modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
            createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
            profilePicture: element.profilePicture,
          }),
        );
      setInstanceAppoinmentGridArray(healthcampAppoinmentArr);
    }
    if (healthcampInsttanceAppoinmentValue?.errorMsg !== "") {
      setNotAdministratorMsg(healthcampInsttanceAppoinmentValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcampInsttanceAppoinmentValue]);

  useEffect(() => {
    if (addResponse?.successMsg !== "") {
      setPatientCheckInMsg(addResponse?.successMsg);
      setCheckInMsgType("success");
    }
    if (addResponse?.errorMsg !== "") {
      setCheckInMsgType("error");
      setPatientCheckInMsg(addResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  function gotoDetails(rowIndex: any) {
    history.push(`/patientdetails/${rowIndex}`);
  }
  const showAptDialog = () => {
    setShowAppointmentDialog(true);
    dispatch(clearAppointmentListOfHealthcampAPIRes());
  };

  const checkInDialog = (element: string) => {
    setPatientId(element);
    setShowCheckInDialog(true);
  };

  const addCheckInMsgClose = (passedVal: boolean) => {
    if (passedVal) {
      setPatientCheckInMsg("");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "patientId",
      headerName: "View",
      minWidth: 100,
      align: "left",

      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      editable: false,
      minWidth: 200,
      align: "left",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      editable: false,
      minWidth: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      minWidth: 180,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone number",
      editable: false,
      minWidth: 120,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      editable: false,
      minWidth: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: "Create Date",
      editable: false,
      minWidth: 120,
      align: "left",
      flex: 1,
    },
    {
      field: "checkInId",
      headerName: "Action",
      minWidth: 120,
      align: "left",

      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            checkInDialog(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Check In
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        {notAdministratorMsg !== "" ? (
          <Grid item>
            <Alert severity="error">{notAdministratorMsg}</Alert>
          </Grid>
        ) : null}
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => showAptDialog()}
                  >
                    Add Appointment
                  </Button>
                </Grid>
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                    sx={{ flex: "end" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse?.patientList &&
          healthcampInsttanceAppoinmentValue?.getAppoinmentListOfHealthcampResponse?.patientList
            .length ? (
            <DataGrid
              rows={instanceAppoinmentGridArray}
              columns={columns}
              getRowId={(row) => row.patientId}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
      {showAppoinmentDialog ? (
        <AddAppoinmentDialog
          filterDialogEnable={showAppoinmentDialog}
          filterDialogUpdateState={updateAppointmentDialogValFromChild}
        />
      ) : null}
      {showCheckInDialog ? (
        <AddCheckInDialogBySession
          patientId={patientId}
          instanceHealthcampId={instanceHealthcampId}
          checkInDialogEnable={showCheckInDialog}
          checkInDialogUpdateState={updateCheckInValFromChild}
        />
      ) : null}
      {patientCheckInMsg !== "" ? (
            <MWSnackbar
              msg={patientCheckInMsg}
              type={checkInMsgType}
              alertClose={addCheckInMsgClose}
            />
          ) : null}
    </Box>
  );
}
