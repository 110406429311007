import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import {
  Pagination,
  Typography,
  Box,
  Button,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar, GridCellParams } from "@mui/x-data-grid";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { GetPatientDetailsListByCompetitionIdBody } from "redux/PatientDetailsListOfPatientByCompetitionId/Model";
import AddDataPointDialog from "pages/PatientDataDetails/AddDataPointDialog";
import { getPatientDetailsListByCompitionId } from "redux/PatientDetailsListOfPatientByCompetitionId/API";
import { AddPatientDetailsListInCompetitionIdBody } from "redux/AddPatientDetailsListInCompetitionId/Model";
import { addPatientDetailsListInCompitionId } from "redux/AddPatientDetailsListInCompetitionId/API";
import { clearAddPatientAPIRes } from "redux/addHealthcampPatient/API";
import { clearPatientDataPointClearAPIRes } from "redux/addPointData/API";
import MWSnackbar from "component/MWSnackbar";
import DocumentViewer from "pages/PatientDataDetails/DocumentViewer";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientDetailsListByCompetition() {
  const loadingMarkup = <AdminLoader />;
  let query = useQuery();
  const competitionPatientDetailsId = query.get("documentid");
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const [showAddDataPointDialog, setShowAddDataPointDialog] = React.useState(false);
  const updateDialogState = () => {
    setShowAddDataPointDialog(true);
  };
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
  };

  const history = useHistory();
  const [page, setPage] = React.useState(1);

  const patientDetailsBody = {
    competitionId: String(competitionid),
    patientId: String(patientid),
  } as GetPatientDetailsListByCompetitionIdBody;
  const addPatientDetailsInCompetitionRes = useSelector(
    (state: AppState) => state.addPatientDetailsInCompetitionValue,
  );

  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName,
  );
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const [patientDetailsId, setPatientDetailsId] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, page));
    }
  };
  useEffect(() => {
    if (competitionPatientDetailsId !== null) {
      setDocViwerActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionPatientDetailsId]);
  useEffect(() => {
    if (caseHistoryPtName !== undefined) {
      setPageTitle(`Patient Details List of ${caseHistoryPtName}`);
    } else {
      setPageTitle("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  useEffect(() => {
    dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, page));
    dispatch(getPatientCaseList(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);

  const patientPointDataList = useSelector(
    (state: AppState) => state.getPatientDetailsByCompetitionValue,
  );

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDetailsListByCompitionId(patientDetailsBody, value));
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages),
        )
      : setPageListCount("0");

    patientPointDataList?.patientDetailsListOfCompetitionIdRes?.count !== undefined
      ? setListCount(String(patientPointDataList?.patientDetailsListOfCompetitionIdRes?.count))
      : setListCount("0");

    if (
      patientPointDataList?.patientDetailsListOfCompetitionIdRes?.patientDetailsList !== undefined
    ) {
      let dataPointArr =
        patientPointDataList?.patientDetailsListOfCompetitionIdRes?.patientDetailsList.map(
          (element: any) => ({
            id: element.id,
            description: element.description,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(patientPointDataList?.patientDetailsListOfCompetitionIdRes?.numberOfPages),
      );
    }
  }, [patientPointDataList]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 150,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            setSelectedItem(Object.assign(params.row, params.id));
            history.push(
              `/patientdetailslist/${competitionid}/${patientid}?documentid=${params.row.id}`,
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "id",
      headerName: "Patient Details Id",
      type: "string",
      width: 150,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
  ];
  const addPatientDetailsInCompetition = () => {
    let addPatientDetailsBody = {
      competitionId: String(competitionid),
      patientDetailsId: String(patientDetailsId),
    } as AddPatientDetailsListInCompetitionIdBody;
    dispatch(addPatientDetailsListInCompitionId(addPatientDetailsBody));
  };
  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <TextField
                    label="Enter Patient Details Id"
                    variant="outlined"
                    placeholder="Please provide Patient Id"
                    onChange={(event) => setPatientDetailsId(event.target.value)}
                    fullWidth
                    id="patientDetailsID"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={addPatientDetailsInCompetition}>
                    Link Existing Patient Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
                pt={2}
              >
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                <Pagination
                  color="primary"
                  count={Number(pageListCount)}
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const [docViwerActive, setDocViwerActive] = React.useState(false);
 
  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
    const doesAnyHistoryEntryExist = history.location.key
    if(doesAnyHistoryEntryExist){
       history.goBack()
    }
  
  };
  const [selectedItem, setSelectedItem] = React.useState<any>(({} as any) || undefined);
  const goToPatientDetails = () => {
    history.push(`/patientdetails/${patientid}`);
  };

  // Call Add Patient to HealthCamp Store
  const addPointDataDetailsRes = useSelector((state: AppState) => state.addPointDataDetailsRes);
  useEffect(() => {
    if (addPointDataDetailsRes?.addPatientDataDetailsRes?.message !== undefined) {
      if (addPointDataDetailsRes?.addPatientDataDetailsRes?.message !== "") {
        dispatch(
          addPatientDetailsListInCompitionId({
            competitionId: String(competitionid),
            patientDetailsId: String(
              addPointDataDetailsRes?.addPatientDataDetailsRes?.patientDetailId,
            ),
          } as AddPatientDetailsListInCompetitionIdBody),
        );
        dispatch(clearPatientDataPointClearAPIRes());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPointDataDetailsRes]);

  useEffect(() => {
    if (addPatientDetailsInCompetitionRes?.successMsg !== "") {
      dispatch(clearAddPatientAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDetailsInCompetitionRes]);

  const updateAddPatientDetailsInCompetitionRes = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddPatientAPIRes());
    }
  };

  return (
    <Box>
      {patientPointDataList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true} 
                title={pageTitle}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={reloadActionFromChild}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button variant="contained" onClick={goToPatientDetails}>
                    View Patient Dashboard
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={() => updateDialogState()}>
                    Add New Patient Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {patientPointDataTable}
          {showAddDataPointDialog ? (
            <AddDataPointDialog
              addDataPointDialogEnable={showAddDataPointDialog}
              addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
              page={page}
            />
          ) : null}
          {docViwerActive ? (
            <DocumentViewer
              dialogState={docViwerActive}
              dialogClose={dialogClose}
              value={selectedItem}
              page={page}
              // toggleState={toggleState}
            />
          ) : null}
        </Stack>
      </Container>
      {addPatientDetailsInCompetitionRes?.successMsg !== "" ? (
        <MWSnackbar
          msg={addPatientDetailsInCompetitionRes?.successMsg}
          type="success"
          alertClose={updateAddPatientDetailsInCompetitionRes}
        />
      ) : null}
    </Box>
  );
}
