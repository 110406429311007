import { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { Box, Button, Card, CardContent } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import MWExceptionList from "../../component/MWExceptionList";
import { getLastModifiedPatientListApi } from "../../redux/LastModifiedPatientList/API";
import { PatientDataList } from "../../redux/LastModifiedPatientList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ModifiedPatientList() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getLastModifiedPatientListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // call Store
  const lastModifiedPatientList = useSelector(
    (state: AppState) => state.getLastModifiedPatientListValue,
  );

  const lastModifiedPatientListValue =
    lastModifiedPatientList?.getLastModifiedPatientListRes?.patientDataList;

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(lastModifiedPatientList?.loading));
    if (lastModifiedPatientListValue !== undefined) {
      let dataPointArr = lastModifiedPatientListValue.map((element: PatientDataList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastModifiedPatientList, lastModifiedPatientList]);

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      minWidth: 80,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: false,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      minWidth: 150,
      align: "left",
    },
    {
      field: "userName",
      headerName: "User Name",
      sortable: true,
      minWidth: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      minWidth: 80,
      align: "right",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 150,
      align: "left",
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      editable: false,
      minWidth: 150,
      align: "left",
    },
  ];

  return (
    <Card>
      <CardContent>
        <Box>
          {lastModifiedPatientListValue && lastModifiedPatientListValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
