import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Pagination, Typography, Box, Button, Grid, Tooltip, AlertColor } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridCellParams, GridSelectionModel } from "@mui/x-data-grid";
import { AppState } from "redux/store/Store";
import MWExceptionList from "component/MWExceptionList";
import {
  GetSessionPatientDetailsListBody,
  PatientDetailsList,
} from "redux/HealthcampSessionList/SessionPatientDetailsList/Model";
import {
  FilterPatientDetailsByObservation,
  FilterPatientDetailsByObservationBody,
} from "redux/HealthcampSessionList/FilterPatientDetails/Model";
import { getSessionPatientDetailsListApi } from "redux/HealthcampSessionList/SessionPatientDetailsList/API";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterPatientDetailsDialog from "../PatientListBySessionId/FilterPatientDetailsDialog";
import PatientDetailsViewer from "./PatientDetailsViewer";
import { filterPatientDetailsObservation } from "redux/HealthcampSessionList/FilterPatientDetails/API";
import { patientDetailsTypeList } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/API";
import {
  assignPatientDetailsTypeSelectedItem,
  clearAssignPatientDetailsTypeRes,
} from "redux/AssignPatientDetailsType/API";
import MWSnackbar from "component/MWSnackbar";
import { checkReturnValue } from "component/Utility";
import CustomPatientDetailsListOfSessionHeader from "./CustomPatientDetailsListOfSessionHeader";

type Props = {
  patientDetailsLoader: (value: boolean) => void;
  count: (value: string) => void;
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientDetailsListBySessionId({ patientDetailsLoader, count }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const patientDetailsId = query.get("documentid");
  const obType = query.get("obType");
  const date = query.get("date");
  const operationType = query.get("operationType");
  const parameters = query.get("parameters");
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const { instanceHealthcampId } = useParams() as {
    instanceHealthcampId: string;
  };
  const { sessionId } = useParams() as {
    sessionId: string;
  };

  useEffect(() => {
    if (patientDetailsId !== null) {
      setDocViwerActive(true);
    } else {
      setDocViwerActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsId]);

  const sessionPatientDetailsList = useSelector(
    (state: AppState) => state.getSessionPatientDetailsListValue,
  );
  const filterpatientDetailsByObservationList = useSelector(
    (state: AppState) => state.filterPatientDetailsByObservation,
  );
  const [page, setPage] = React.useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [showFilterDialog, setShowFilterDialog] = React.useState(false);
  const updateFilterDialogValFromChild = (dialogPassedVal: boolean) => {
    setShowFilterDialog(dialogPassedVal);
  };

  useEffect(() => {
    let apiBody = {
      healthcampSessionId: sessionId,
    } as GetSessionPatientDetailsListBody;
    dispatch(getSessionPatientDetailsListApi(apiBody, 1));
    dispatch(patientDetailsTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (obType !== null && date !== null && operationType !== null && parameters !== null) {
      let paramValue = parameters.split(",");
      const PatientSearchByObservationBody = {
        patientObservationType: decodeURIComponent(obType),
        healthcampSessionId: sessionId,
        startDate: dayjs(decodeURIComponent(date)).format("YYYY-MM-DD"),
        parameters: paramValue,
        operationType: operationType,
      } as FilterPatientDetailsByObservationBody;
      dispatch(filterPatientDetailsObservation(PatientSearchByObservationBody, value));
    } else {
      let apiBody = {
        healthcampSessionId: sessionId,
      } as GetSessionPatientDetailsListBody;
      dispatch(getSessionPatientDetailsListApi(apiBody, value));
    }
  };
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    patientDetailsLoader(sessionPatientDetailsList?.loading);
    sessionPatientDetailsList?.getSessionPatientDetailsListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(sessionPatientDetailsList?.getSessionPatientDetailsListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (
      sessionPatientDetailsList?.getSessionPatientDetailsListRes?.patientDetailsList !== undefined
    ) {
      count(String(sessionPatientDetailsList?.getSessionPatientDetailsListRes?.count));
      let dataPointArr =
        sessionPatientDetailsList?.getSessionPatientDetailsListRes?.patientDetailsList.map(
          (element: PatientDetailsList) => ({
            id: element.id,
            patientId: element.patient.id,
            description: element.description,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            modifiedDate: dayjs(element.modifiedDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
            patientDetailsType:
              checkReturnValue(element?.patientDetailsType) !== "Not Set"
                ? checkReturnValue(element?.patientDetailsType?.displayValue)
                : "Not Set",
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(sessionPatientDetailsList?.getSessionPatientDetailsListRes?.numberOfPages),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionPatientDetailsList]);

  useEffect(() => {
    patientDetailsLoader(filterpatientDetailsByObservationList?.loading);
    filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes?.numberOfPages !==
    undefined
      ? setPageListCount(
          String(
            filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes
              ?.numberOfPages,
          ),
        )
      : setPageListCount("0");
    if (
      filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes
        ?.patientDetailsList !== undefined
    ) {
      count(
        String(filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes?.count),
      );

      let dataPointArr =
        filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes?.patientDetailsList.map(
          (element: FilterPatientDetailsByObservation) => ({
            id: element.id,
            patientId: element.patient.id,
            description: element.description,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY h:mm A"),
            modifiedDate: dayjs(element.modifiedDate).format("dddd, MMMM D, YYYY h:mm A"),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
            patientDetailsType:
              checkReturnValue(element?.patientDetailsType) !== "Not Set"
                ? checkReturnValue(element?.patientDetailsType?.displayValue)
                : "Not Set",
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(
          filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes?.numberOfPages,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterpatientDetailsByObservationList]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            setSelectedItem(Object.assign(params.row, params.id, params.row.patientId));
            history.push(
              `/healthcampinstance_session/${healthcampid}/${instanceHealthcampId}/${sessionId}?section=patient-details-list&patientid=${params.row.patientId}&documentid=${params.id}`,
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Details Id",
      type: "string",
      minWidth: 130,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 230,
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "patientDetailsType",
      headerName: "Patient Details Type",
      type: "string",
      minWidth: 230,
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      minWidth: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      minWidth: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
    },
  ];
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    obType !== null && date !== null && operationType !== null && parameters !== null
      ? setOpen(true)
      : setOpen(false);
  }, [obType, date, operationType, parameters]);
  const [docViwerActive, setDocViwerActive] = React.useState(false);
  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
  };
  const [selectedItem, setSelectedItem] = React.useState<any>(({} as any) || undefined);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  useEffect(() => {
    dispatch(assignPatientDetailsTypeSelectedItem(selectionModel));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel]);

  const assignPatientDetailsTypeRes = useSelector(
    (state: AppState) => state.assignPatientDetailsTypeRes,
  );
  const [assignPatientDetailsTypeMsg, setAssignPatientDetailsTypeMsg] = React.useState("");
  const [assignPatientDetailsTypeMsgType, setAssignPatientDetailsTypeMsgType] =
    React.useState<AlertColor>("success");

  useEffect(() => {
    if (assignPatientDetailsTypeRes?.successMsg !== "") {
      setAssignPatientDetailsTypeMsgType("success");
      setAssignPatientDetailsTypeMsg(assignPatientDetailsTypeRes?.successMsg);
      setSelectionModel([] as any[]);
    }
    if (assignPatientDetailsTypeRes?.errorMsg !== "") {
      setAssignPatientDetailsTypeMsgType("error");
      setAssignPatientDetailsTypeMsg(assignPatientDetailsTypeRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignPatientDetailsTypeRes]);

  const alertCloseAction = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAssignPatientDetailsTypeRes());
      setAssignPatientDetailsTypeMsg("");
    }
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Tooltip
                    open={open}
                    title={
                      obType !== null &&
                      date !== null &&
                      operationType !== null &&
                      parameters !== null
                        ? `Observation Type= ${obType}, Date= ${dayjs(date).format(
                            "DD/MM/YYYY",
                          )}, Operation Type= ${operationType}, Parameter= ${parameters}`
                        : ""
                    }
                    arrow
                  >
                    <Button
                      variant="outlined"
                      startIcon={<FilterListIcon />}
                      onClick={() => {
                        setShowFilterDialog(true);
                      }}
                    >
                      Filter
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {(dataGridArray && dataGridArray.length) ||
          (filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes
            ?.patientDetailsList &&
            filterpatientDetailsByObservationList?.filterPatientDetailsObservationRes
              ?.patientDetailsList.length) ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: CustomPatientDetailsListOfSessionHeader }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          ) : (
            <MWExceptionList />
          )}
        </Grid>
      </Grid>
      {docViwerActive ? (
        <PatientDetailsViewer
          dialogState={docViwerActive}
          dialogClose={dialogClose}
          value={selectedItem}
          page={page}
        />
      ) : null}
      {showFilterDialog ? (
        <FilterPatientDetailsDialog
          filterDialogEnable={showFilterDialog}
          filterDialogUpdateState={updateFilterDialogValFromChild}
          sessionId={sessionId}
          obType={obType}
          date={date}
          operationType={operationType}
          parameters={parameters}
        />
      ) : null}
      {assignPatientDetailsTypeMsg !== "" && (
        <MWSnackbar
          msg={assignPatientDetailsTypeMsg}
          type={assignPatientDetailsTypeMsgType}
          alertClose={alertCloseAction}
        />
      )}
    </Box>
  );
}
