import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  Typography,
  Box,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { AppState } from "redux/store/Store";
import { ObservationList } from "redux/getExtractObservationByPatientId/Model";
import MWExceptionList from "component/MWExceptionList";
import { duplicateObservationListApi } from "redux/DuplicatePatientObservationList/API";
import { DuplicateObservationListBody } from "redux/DuplicatePatientObservationList/Model";
type Props = {
  patientduplicateid: any;
};

export default function DuplicateObservationDetails({ patientduplicateid }: Props) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.duplicatePatientObservationListValue,
  );
  // extract observation list api call ...
  useEffect(() => {
    if (patientduplicateid) {
      dispatch(
        duplicateObservationListApi(
          { patientId: patientduplicateid } as DuplicateObservationListBody,
          page,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const [pageListCount, setPageListCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      duplicateObservationListApi(
        { patientId: patientduplicateid } as DuplicateObservationListBody,
        value,
      ),
    );
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    getExtractObservationValue?.duplicateObservationListRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(getExtractObservationValue?.duplicateObservationListRes?.numberOfPages),
        )
      : setPageListCount("0");
    if (getExtractObservationValue?.duplicateObservationListRes?.observationList !== undefined) {
      let dataPointArr =
        getExtractObservationValue?.duplicateObservationListRes?.observationList.map(
          (element: ObservationList) => ({
            id: element.id,
            value: element.value,
            startDate: dayjs(element.startDate).format("DD/MM/YYYY"),
            displayValue: element.patientObservationType.displayValue,
            modifiedDate: dayjs(element.modifiedDate).format("DD/MM/YYYY"),
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(getExtractObservationValue?.duplicateObservationListRes?.numberOfPages),
      );
    }
  }, [getExtractObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "displayValue",
      headerName: "Name",
      type: "string",
      width: 230,
      editable: false,
      sortable: true,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];

  const duplicatePointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {duplicatePointDataTable}
        </Stack>
      </Container>
    </Box>
  );
}
