import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MWPageTitle from "../component/MWPageTitle";
import MWExceptionList from "../component/MWExceptionList";
import PageLayout from "./Layout/PageLayout";
import {
  AppointmentListBody,
  AppointmentListValue,
} from "../redux/Appointment/AppointmentList/Model";
import { appointmentListCallApi } from "../redux/Appointment/AppointmentList/ApiCall";
import { appointmentDeactivateCallApi } from "../redux/Appointment/AppointmentDeactive/ApiCall";
import MWResponseBanner from "../component/MWResponseBanner";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function AppointmentList() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [apptArray, setApptGridArray] = useState<any[]>([]);
  const [listCount, setListCount] = React.useState("");
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(appointmentListCallApi(appointmentBody, value));
  };

  const closeBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  };
  const appointmentBody = {
    appointmentStatus: "",
  } as AppointmentListBody;

  function deactivate(appointmentId: number) {
    dispatch(appointmentDeactivateCallApi(appointmentId));
    //dispatch(appointmentListCallApi(appointmentBody, page));
  }
  const DeactivateResponse = useSelector(
    (state: AppState) => state.deleteAppointment, //.successMsg//response.message
  );

  const AdminAppointmentList = useSelector((state: AppState) => state.allApptList);
  const pageCount = useSelector(
    (state: AppState) => state.allApptList?.appointmentListRes?.numberOfPages,
  );

  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState("");
  const [bannerMsg, setBannerMsg] = useState("");

  const [deleteMsg, setDeleteMsg] = useState("");
  const [addQuestionAnswerToastActive, setAddQuestionAnswerToastActive] = useState(false);
  const toggleSuccessAddWuestionAnswerActive = () => {
    setAddQuestionAnswerToastActive(
      (addQuestionAnswerToastActive) => !addQuestionAnswerToastActive,
    );
    setAddQuestionAnswerToastActive(false);
  };

  const toastQuestionAnswerSuccessMarkup = addQuestionAnswerToastActive ? (
    <Snackbar
      open={addQuestionAnswerToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessAddWuestionAnswerActive}
    >
      <Alert
        onClose={toggleSuccessAddWuestionAnswerActive}
        severity="success"
        sx={{ width: "100%" }}
      >
        {deleteMsg}
      </Alert>
    </Snackbar>
  ) : null;

  useEffect(() => {
    AdminAppointmentList?.appointmentListRes?.count !== undefined
      ? setListCount(String(AdminAppointmentList?.appointmentListRes?.count))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAppointmentList]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(AdminAppointmentList?.loading));
    if (AdminAppointmentList?.appointmentListRes?.appointmentList !== undefined) {
      let paymentList = AdminAppointmentList?.appointmentListRes?.appointmentList?.map(
        (element: AppointmentListValue) => ({
          patientName: element.patientName !== "" ? element.patientName : "",
          createDate:
            element.createDate !== null ? dayjs(element.createDate).format("ddd, MMM D, YYYY") : "",
          endDate: element.end !== null ? dayjs(element.end).format("ddd, MMM D, YYYY") : "",
          modifiedDate:
            element.modifiedDate !== null
              ? dayjs(element.modifiedDate).format("ddd, MMM D, YYYY")
              : "",
          appoinmentStatus: element.status !== "" ? element.status : "",
          id: element.id,
        }),
      );
      console.log("RUPAKpaymentList", paymentList);
      setApptGridArray(paymentList);
    } else {
      setApptGridArray([] as AppointmentListValue[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAppointmentList]);

  useEffect(() => {
    if (DeactivateResponse?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(DeactivateResponse?.errorMsg);
    }
  }, [DeactivateResponse]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(DeactivateResponse?.loading));
    if (DeactivateResponse.successMsg !== "") {
      setAddQuestionAnswerToastActive(true);
      setDeleteMsg(DeactivateResponse?.successMsg);
      dispatch(appointmentListCallApi(appointmentBody, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeactivateResponse]);

  const columns: GridColDef[] = [
    {
      field: "patientName",
      headerName: "Patient Name",
      editable: false,
      minWidth: 250,
      align: "left",
    },
    {
      field: "createDate",
      headerName: "Create Date",
      type: "string",
      minWidth: 200,
      align: "left",
    },
    {
      field: "endDate",
      headerName: "End Date",
      sortable: true,
      minWidth: 200,
      align: "left",
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      sortable: true,
      minWidth: 200,
      align: "left",
    },
    {
      field: "appoinmentStatus",
      headerName: "Status",
      sortable: true,
      minWidth: 150,
      align: "left",
    },
    {
      field: "id",
      headerName: "Deactive",
      minWidth: 150,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          onClick={() => {
            deactivate(params.row.id);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          Deactive
        </Button>
      ),
    },
  ];

  // Reload Appointment API
  const reloadAppointmentList = () => {
    dispatch(appointmentListCallApi(appointmentBody, page));
  };
  useEffect(() => {
    // Do api call here....
    dispatch(appointmentListCallApi(appointmentBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const tableMarkup = (
    <Card>
      <CardHeader
        title={
          <React.Fragment>
            {AdminAppointmentList?.appointmentListRes?.appointmentList?.length && (
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                {!fullScreen && (
                  <Grid item>
                    <Typography>Page: {page}</Typography>
                  </Grid>
                )}

                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageCount)}
                    page={page}
                    onChange={handleChange}
                    sx={{ flex: "end" }}
                  />
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        }
      />
      <CardContent>
        <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          {AdminAppointmentList &&
          AdminAppointmentList?.appointmentListRes?.appointmentList?.length ? (
            <DataGrid
              rows={apptArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <PageLayout>
      <MWPageTitle
        title="Appointment List"
        enableCount={true}
        count={listCount}
        reload={true}
        reloadAction={reloadAppointmentList}
      />
      {tableMarkup}
      {toastQuestionAnswerSuccessMarkup}
      {
        <MWResponseBanner
          bannerOpen={bannerOpen}
          bannerColor={bannerColor}
          responseMsg={bannerMsg}
          bannerClose={closeBannerState}
        />
      }
    </PageLayout>
  );
}
